/* DASHBOARD STYLES STARTS HERE */
.wrapper {
  padding: 100px 50px;
  text-align: center;
}

.table {
  --bs-table-bg: transparent !important;
}

.oja-ellipsis {
  max-width: 150px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.cards_grid {
  display: grid;
  /* To distribute cards in the grid without fixing the number of cols, 
    use auto-fit, eg grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
  */
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 10px;
  grid-auto-rows: auto;
  grid-auto-flow: row dense;
  box-shadow: 0px 1px 3px grey;
  padding: 20px;
}

.card_single {
  border: 1px solid black;
  border-radius: 5px;
  transform-origin: 0 0;
  padding: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.card_single.draggable {
  border-color: transparent;
  box-shadow: 0px 1px 3px darkgrey;
}

.empty-state .empty-state-btn {
  width: 20%;
  margin: auto;
}

.empty-state .empty-state-btn button {
  background-color: #6e449b;
}

.google-analytics {
  text-decoration: none;
  padding: 7px 32px;
  border: none;
  background: #4285f4 !important;
  color: #ffffff;
  border-radius: 8px;
}
.google-analytics:hover {
  color: #ffffff;
}

/* Pagination starts */
.active > .page-link {
  background-color: #6e449b;
  border-color: #6e449b;
}

.page-link {
  color: #6e449b;
}

.pagination-right {
  display: flex;
  flex-direction: row-reverse;
}
/* Pagination ends */

/* dropdown starts */
.oja-dropdown {
  position: relative;
  display: inline-block;
}
.oja-dropdown img {
  cursor: pointer;
}
.oja-dropdown-content {
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  min-width: 170px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 99999;
  right: 0;
}
.oja-dropdown-content a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}
.oja-dropdown-content a:hover {
  background-color: #6e449b;
  color: #ffffff;
}
.oja-dropdown:hover .oja-dropdown-content {
  display: block;
}
/* dropdown ends */

.oja-dashboard .oja-dashboard-sidebar {
  padding-top: 72px;
  width: 265px;
  height: 100vh;
  position: fixed;
  overflow: auto;
  border-right: 2px solid rgba(110, 68, 155, 0.3);
}

.oja-dashboard .oja-dashboard-navbar {
  position: fixed;
  width: 100%;
  z-index: 800;
  background: #fbf8ff;
}

.oja-dashboard .oja-dashboard-content {
  padding-top: 100px !important;
  margin-left: 266px;
  padding: 2rem;
  background: #ffffff;
  min-height: 100vh;

  position: relative;
}

.oja-dashboard .heading h1 {
  font-family: "Clash Display";
  font-style: normal;
  font-weight: 600;
  font-size: 28px;
  line-height: 32px;
  color: #6e449b;
}

.oja-dashboard-header .heading {
  display: flex;
  justify-content: space-between;
}

.oja-dashboard-header .heading button {
  border: 1px solid rgba(110, 68, 155, 0.3);
  border-radius: 5px;
  background: white;
  height: 34px;
  width: 200px;
}

/* DASHBOARD STYLES STOPS HERE */

.oja-order .nav-tabs {
  all: unset;
  display: flex !important;
  list-style-type: none;
}

.oja-order .nav-tabs .nav-link.active {
  background-color: #6e449b;
  color: #ffffff;
}

.oja-order .nav-tabs .nav-link {
  color: #000000;
}

/* DASHBOARD SIDEBAR STYLES STARTS HERE */

.sidebar-content .sidebar-item.plain img {
  display: none;
}

.oja-sidebar {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.oja-sidebar-container {
  background-color: #fff;
  padding-left: 20px;
  padding-right: 25px;
  overflow: auto;
}

.oja-sidebar-logo {
  margin-top: 13px;
  margin-bottom: 50px;
}

/* DASHBOARD SIDEBAR STYLES STOPS HERE */

/* DASHBOARD SIDEBAR ITEM STYLES STARTS HERE */

.sidebar-item {
  margin-top: 10px;
  padding: 4px 1em;
  display: block;
  transition: background-color 0.15s;
  border-radius: 6px;
}
.sidebar-item:hover {
  background: #5a0b77;
  color: #fff !important;
}
.sidebar-item:hover img,
.sidebar-item.active img {
  filter: brightness(0) invert(1); /* Turns SVG white on hover or active */
}

.sidebar-title {
  display: flex;
  font-family: "Clash Display";
  font-size: 16px;
  line-height: 22px;
  color: #b6b6b6;
  /* justify-content: space-between; */
}
.sidebar-title:hover {
  color: white;
}
.sidebar-title span i {
  display: inline-block;
  width: 1.5em;
}
.sidebar-title .toggle-btn {
  cursor: pointer;
  transition: transform 0.3s;
  margin-left: auto;
}
.sidebar-item.open > .sidebar-title .toggle-btn {
  transform: rotate(180deg);
}
.sidebar-content {
  padding-top: 0.2em;
  padding-left: 30px;
  height: 0;
  overflow: hidden;
}
.sidebar-item.open ~ .sidebar-content {
  height: auto;
}

.sidebar-item.plain {
  font-family: "Clash Display";
  font-size: 16px;
  line-height: 22px;
  color: #b6b6b6;
  text-decoration: none;
}
.sidebar-item.plain:hover {
  text-decoration: underline;
}

.sidebar-item img {
  margin-right: 10px;
}

/* .sidebar-item.logout {
} */

/* DASHBOARD SIDEBAR ITEM STYLES STOPS HERE */

/* DASHBOARD NAVBAR STYLES STARTS HERE */

.oja-nav-dashboard {
  padding: 15px;
  border-bottom: 2px solid rgba(62, 14, 84, 0.25);
}

.oja-nav-dashboard .oja-nav-logo {
  margin-left: 10px;
}

.oja-nav-dashboard .oja-nav-form {
  margin-left: -80px;
}

.oja-nav-dashboard .form-control {
  border: 1px solid #e5e5e5;
  background: #f8f8f8;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
  border-radius: 8px;
  width: 425px;
  height: 40px;
}

.oja-nav-dashboard .form-control:focus {
  background: #f8f8f8;
  border-radius: 4px;
}

.oja-nav-dashboard .oja-img-item {
  margin-left: auto;
}

.oja-nav-dashboard .oja-img-item > button {
  all: unset;
  cursor: pointer;
  margin-left: 20px;
  margin-right: 20px;
  position: relative;
}
.oja-nav-dashboard .oja-img-item > button::before {
  content: attr(data-count);
  position: absolute;
  top: -0.75rem;
  right: -0.75rem;
  width: 1.5em;
  height: 1.5em;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background-color: red;
  color: white;
}

.oja-nav-dashboard .oja-notification {
  position: absolute;
  right: 30px;
}
.oja-nav-dashboard .oja-notification .card {
  width: 400px;
}
.oja-nav-dashboard .oja-notification .card .clear {
  margin-top: auto;
  margin-bottom: auto;
  color: red;
  border: none;
  background: none;
}
/* .oja-notification-img {
  margin-left: 20px;
  position: relative;
} */
.oja-notification-img::before {
  content: "5";
  position: absolute;
  top: -0.75rem;
  right: -0.75rem;
  width: 1.5em;
  height: 1.5em;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background-color: red;
  color: white;
}

/* DASHBOARD NAVBAR STYLES STOPS HERE */

/* DASHBOARD HOME STYLES STARTS HERE */

.oja-dashboard-home .card {
  border: none;
  border-radius: 4px;
  background: #f9f4ff;
}

.oja-dashboard-home .card .card-body p {
  text-align: center;
  font-weight: 700;
  font-size: 14px;
  margin-bottom: 10px;
}

.dashboard-card .card .card-body {
  padding: 0;
}
.dashboard-card .card {
  padding: 12px;
  padding-top: 16px;
}

@property --p {
  syntax: "<number>";
  inherits: true;
  initial-value: 1;
}

.pie {
  --p: 100;
  --b: 20px;
  --c: darkred;
  --w: 150px;

  width: var(--w);
  aspect-ratio: 1;
  position: relative;
  display: inline-grid;
  margin: 5px;
  place-content: center;
  font-size: 25px;
  font-weight: bold;
}
.pie:before,
.pie:after {
  content: "";
  position: absolute;
  border-radius: 50%;
}
.pie:before {
  inset: 0;
  background: radial-gradient(farthest-side, var(--c) 98%, #0000) top/var(--b)
      var(--b) no-repeat,
    conic-gradient(var(--c) calc(var(--p) * 1%), #0000 0);
  -webkit-mask: radial-gradient(
    farthest-side,
    #0000 calc(99% - var(--b)),
    #000 calc(100% - var(--b))
  );
  mask: radial-gradient(
    farthest-side,
    #0000 calc(99% - var(--b)),
    #000 calc(100% - var(--b))
  );
}
.pie:after {
  inset: calc(50% - var(--b) / 2);
  background: var(--c);
  transform: rotate(calc(var(--p) * 3.6deg))
    translateY(calc(50% - var(--w) / 2));
}
.animate {
  animation: p 1s 0.5s both;
}
.no-round:before {
  background-size: 0 0, auto;
}
.no-round:after {
  content: none;
}
@keyframes p {
  from {
    --p: 0;
  }
}

.info-card-container {
  margin-top: 27px;
}

.info-card {
  background-color: #f9f4ff;

  padding: 20px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  font-weight: 600;
  gap: 13px;
}
.info-card .icon {
  font-size: 24px;
  margin-right: 15px;
}
.info-card .number {
  font-size: 1rem;
  font-weight: normal;
}
.info-card .info-title {
  margin-bottom: 8px;
}

/* DASHBOARD HOME STYLES STOPS HERE */

/* DASHBOARD CUSTOMER STYLES STARTS HERE */

.oja-customer-dashboard .heading .form-select {
  border: 1px solid rgba(110, 68, 155, 0.3);
  border-radius: 5px;
  height: 34px;
}

.oja-customer-dashboard .heading .form-select:focus {
  background-color: #6e449b;
  color: #fff;
}

.oja-customer-dashboard .heading button {
  border: 1px solid rgba(110, 68, 155, 0.3);
  border-radius: 5px;
  background: white;
  height: 34px;
  width: 193px;
}

.oja-customer-dashboard .heading button,
.form-select {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #8b8c8c;
}

/* DASHBOARD CUSTOMER STYLES STOPS HERE */

/* DASHBOARD ADD CUSTOMER STYLES STARTS HERE */

.oja-add-customer-dashboard .profile-items {
  height: auto;
  background: #f7e7ff;
  margin-top: 26px;
  margin-left: -33px;
  margin-right: -33px;
}

.oja-add-customer-dashboard .profile-img {
  width: 150.57px;
  height: 142.35px;
  margin-top: 25px;
  margin-left: 22.91px;
  cursor: pointer;
  object-fit: cover !important;
}

.oja-add-customer-dashboard .profile-items .info {
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 140%;
  color: rgba(110, 68, 155, 0.3);
  margin-left: 44.01px;
  margin-top: 3.39px;
}

.oja-add-customer-dashboard .profile-items-input .form {
  margin-bottom: 0 !important;
}

.oja-add-customer-dashboard .profile-items-input .error {
  color: red;
  font-size: 15px;
}

.oja-add-customer-dashboard .profile-items-input .formik-input {
  background: transparent;
  width: 453px;
  height: 48px;
  border: 1px solid #6e449b;
  border-radius: 8px;
}

.oja-add-customer-dashboard .profile-details a {
  color: #000 !important;
}

.oja-add-customer-dashboard .profile-details .card {
  max-width: 725px;
  border: 3px solid rgba(110, 68, 155, 0.3);
  border-radius: 8px;
}

.oja-add-customer-dashboard .profile-details .card .cus-details p {
  font-family: "Clash Display";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #6e449b;
}

.oja-add-customer-dashboard .card .profile-items-input .formik-input {
  max-width: 453px;
  border: 1px solid rgba(110, 68, 155, 0.3);
  border-radius: 8px;
  height: 44px;
}

.oja-add-customer-dashboard
  .card
  .profile-items-input
  .formik-input::placeholder {
  font-family: "Clash Display";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: rgba(110, 68, 155, 0.3);
}

.oja-add-customer-dashboard .profile-details .profile-btn {
  max-width: 725px;
}

.oja-add-customer-dashboard .profile-details .edit {
  padding: 7px 32px;
  border: none;
  background: #ffde17;
  border-radius: 8px;
}

.oja-add-customer-dashboard .profile-details button {
  width: 101px !important;
  color: #000;
}

.oja-add-customer-dashboard .profile-details a .edit {
  padding: 7px 32px;
  border: none;
  background: #ffde17;
  border-radius: 8px;
}

/* DASHBOARD ADD CUSTOMER STYLES STOPS HERE */

/* DASHBOARD VIEW CUSTOMER STYLES STARTS HERE */

.oja-add-customer-dashboard .profile-items .view-profile-img {
  width: 150.57px;
  height: 142.35px;
  margin-left: 30.01px;
  margin-top: 15px;
}

.oja-add-customer-dashboard .profile-items .view-profile-items-det {
  margin-left: 57px;
  margin-top: 56.41px;
}

.oja-add-customer-dashboard .profile-items .view-profile-items-det h1 {
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 30px;
  color: #6e449b;
  letter-spacing: -0.02em;
}

.oja-add-customer-dashboard .profile-items .view-profile-items-det p {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #6e449b;
}

.oja-add-customer-dashboard .profile-details .view-profile-btn {
  max-width: 725px;
}

.oja-add-customer-dashboard .table-title {
  font-style: normal;
  font-weight: 400;
  font-size: 30px;
  line-height: 24px;
  color: #6e449b;
}

/* DASHBOARD VIEW CUSTOMER STYLES STOPS HERE */

/* DASHBOARD CUSTOMER REP STYLES STARTS HERE */
.oja-customer-rep-dashboard .card {
  border: 1.5px solid rgba(110, 68, 155, 0.3);
  border-radius: 14px;
}

.user-btn,
.user-btn-outline {
  height: 40px;
  display: flex !important;
  justify-content: center;
  align-items: center;
  margin: 0 !important;
  border-radius: 8px;
  font-weight: 600;
  font-size: 16px;
}

.user-btn-outline {
  background-color: transparent;
  margin-top: 12px !important;
}

.all-staff-list-item .card-body {
  padding: 0;
}
.all-staff-list-item {
  background: #f9f4ff;
  border: 2px solid #f5f6fa !important;
  padding: 44px 40px !important;
}

.all-staffs-container {
  display: flex;
  flex-direction: column;
  gap: 32px;
  margin-top: 28px;
}

.oja-customer-rep-dashboard .rep-details h1 {
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 30px;
  letter-spacing: -0.02em;
  color: #6e449b;
}

.oja-customer-rep-dashboard .rep-details p {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #6e449b;
  margin-bottom: 0;
}

.oja-customer-rep-dashboard .loading {
  margin-top: 25%;
}

.dropdown-toggle {
  border: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-color: transparent;
  background: none !important;
}

.dropdown-toggle:focus {
  background-color: transparent;
  box-shadow: none !important;
}

.dropdown-menu {
  border: 1px solid rgba(110, 68, 155, 0.3);
  box-shadow: -4px 4px 17px rgba(0, 0, 0, 0.25);
  border-radius: 8px;
  padding: 0;
  inset: 0px auto auto -88px !important;
}

.dropdown-item:hover:nth-child(1) {
  background: #6e449b;
  border-radius: 8px 8px 0px 0px;
  color: #ffffff;
}

.dropdown-item:hover:nth-child(2) {
  background: #6e449b;
  border-radius: 0px 0px 8px 8px;
  color: #ffffff;
}

/* DASHBOARD CUSTOMER REP STYLES STOPS HERE */

/* DASHBOARD ADD CUSTOMER REP STYLES STARTS HERE */

.oja-add-customer-rep-dashboard a {
  color: #000;
}

/* .oja-add-customer-rep-dashboard .card { */
/* max-width: 677px; */
/* border: 1.5px solid #6e449b; */
/* border-radius: 14px; */
/* } */

.oja-add-customer-rep-dashboard .card .profile-img {
  width: 150.57px;
  height: 142.35px;
  object-fit: cover;
  cursor: pointer;
}

.oja-add-customer-rep-dashboard .card .customer-reps-input {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 20px;
  column-gap: 75px;
  row-gap: 41px;
  max-width: 1131px;
}

.oja-add-customer-rep-dashboard .card .con,
.oja-add-rider-dashboard .con {
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 140%;
  color: rgba(110, 68, 155, 0.3);
  display: flex;
  align-items: center;
  position: relative;
  overflow: hidden;
}
.oja-add-rider-dashboard .show,
.oja-add-customer-rep-dashboard .show {
  padding-left: 4px;
  padding-right: 10px;
  position: absolute;
  z-index: 20;
  right: 3px;
}
.oja-add-customer-rep-dashboard .form {
  margin-bottom: 0 !important;
}

.oja-add-customer-rep-dashboard .formik-input.pswd {
  padding-right: 2.5rem;
}
.oja-add-customer-rep-dashboard .formik-input {
  max-width: 528px;
  width: 100%;
  height: 54px;
  font-size: 16px;
  margin-top: 0;
  border: 1px solid #979797;
  border-radius: 8px;
}

.oja-add-customer-rep-dashboard .formik-input::placeholder {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: rgba(110, 68, 155, 0.3) !important;
}

.no-padding {
  padding: 0 !important;
}
.add-staffs-form .card {
  border-bottom: 0 !important;
}

/* .oja-add-customer-rep-dashboard .basic-multi-select .select__control {
  width: 453px !important;
  border-radius: 8px !important; */
/* height: 54px !important; */
/* } */

/* .oja-add-customer-rep-dashboard .add-rep-btn {
  max-width: 680px;
  text-align: right;
} */

.oja-add-customer-rep-dashboard .add-rep-btn button {
  width: 103px !important;
  padding: 7px 32px;
  border: none;
  background: #ffde17 !important;
  border-radius: 8px;
  margin: auto;
  color: rgba(0, 0, 0, 0.7);
}

.add-staffs-form {
  margin-top: 40px;
}

/* DASHBOARD ADD CUSTOMER REP STYLES STOPS HERE */

/* DASHBOARD ADD RIDER STYLES STARTS HERE */

.oja-add-rider-dashboard a {
  margin-bottom: 36px;
}

.oja-add-rider-dashboard a p {
  color: #000;
}

.add-rider-item-container {
  margin-bottom: 25px;
}

/* .oja-add-rider-dashboard .card {
  max-width: 621px;
  border: 1px solid rgba(110, 68, 155, 0.3);
  border-radius: 8px;
} */

.oja-add-rider-dashboard .profile-img {
  cursor: pointer;
}

.oja-add-rider-dashboard .card .form-group {
  text-align: -webkit-center;
}

.oja-add-rider-dashboard .card .form-group .info {
  color: #6e449b;
}

.upload-image-cont {
  margin-bottom: 72px;
}

.upload-image {
  border-radius: 100%;
  border: 1px solid #e8e8e8;
  width: 200px;
  height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 23px;
  background: #fff;
  flex-direction: column;
  cursor: pointer;
}

.upload-image.uploaded-image .remove-btn {
  z-index: 2000;
}
.upload-image.uploaded-image {
  gap: 10px;
}
.upload-image .selected-img img {
  object-fit: cover;
  width: 100%;
  height: 100%;
  border-radius: 8px;
}
.upload-image .selected-img {
  width: 140px;
  height: 60px;
  overflow: hidden;
}
.upload-image .remove-btn {
  margin: 0;
}
.upload-image p {
  color: rgba(0, 0, 0, 0.5);
  font-weight: 500;
}

.text-area {
  width: 100%;
  /* height: 44px; */
  border: 1px solid #6e449b;
  border-radius: 8px !important;
  padding: 0.375rem 0.75rem;
}

.oja-add-rider-dashboard .card .con {
  max-width: 528px;
}

.oja-add-rider-dashboard .card .form-control,
.formik-input {
  width: 100%;
  height: 44px;
  padding: 0.375rem 0.75rem;
  outline: none;
  border: 1px solid #979797;
  border-radius: 8px;
  max-width: 528px;
  height: 54px;
  font-size: 16px;
  margin-top: 0 !important;
}

.oja-add-rider-dashboard .formik-input.pswd {
  padding-right: 2.5rem;
}

.riders-id-password {
  margin-bottom: -1rem;
}

.oja-add-rider-dashboard .card .is-text-area {
  height: 100%;
}

.oja-add-rider-dashboard .card .form-control,
textarea.formik-input {
  height: 100%;
}

.oja-add-rider-dashboard .card .select__control {
  width: 453px;
  height: 44px;
  border: 1px solid #6e449b !important;
  border-radius: 8px !important;
}

.oja-add-rider-dashboard .card .form-select {
  max-width: 78%;
  height: 44px;
  border: 1px solid #6e449b;
  border-radius: 8px;
  color: #6e449b;
}

.oja-add-rider-dashboard .card .form-select .selected {
  color: #8b8c8c !important;
}

.oja-add-rider-dashboard .card .form-select:focus {
  background-color: #6e449b !important;
  color: #fff;
}

.oja-add-rider-dashboard .card .icon {
  padding-left: 30px;
  /* background: url(/svgs/input-profile.svg) no-repeat left; */
  background-position-x: 4px;
  background-size: 20px;
}
.oja-add-rider-dashboard .card .icon.email {
  padding-left: 30px;
  /* background: url(/svgs/input-message.svg) no-repeat left; */
  background-position-x: 4px;
  background-size: 20px;
}
.oja-add-rider-dashboard .card .icon.phone {
  padding-left: 30px;
  /* background: url(/svgs/input-call.svg) no-repeat left; */
  background-position-x: 4px;
  background-size: 20px;
}

/* .oja-add-rider-dashboard .card .add-rider-btn {
  max-width: 521px;
  text-align: right;
} */

.oja-add-rider-dashboard .card .add-rider-btn button {
  width: 133px !important;
  padding: 7px 32px;
  border: none;
  background: #ffde17 !important;
  border-radius: 8px;
  margin: auto;
  color: rgba(0, 0, 0, 0.7);
}

.add-riders-form {
  max-width: 1131px;
}

/* DASHBOARD ADD RIDER STYLES STOPS HERE */
/* DASHBOARD RIDER DETAILS STYLES STARTS HERE */
.oja-rider-details-dashboard .card {
  width: 767px;
  border: 1px solid #6e449b;
  border-radius: 10px;
}
.oja-rider-details-dashboard .card-body {
  text-align: -webkit-center;
}
.oja-rider-details-dashboard .view-input {
  border: 1px solid #6e449b;
  border-radius: 8px;
  width: 100%;
  height: 48px;
}
.oja-rider-details-dashboard .view-input > div {
  margin: 12px;
}

/* DASHBOARD RIDER DETAILS STYLES STOPS HERE */

/* DASHBOARD ORDER STYLES STARTS HERE */

.oja-order-dashboard .card {
  background: #fbf8ff;
  border: 1px solid #6e449b;
  border-radius: 8px;
}

.oja-order-dashboard .card .order-img {
  width: 119.17px;
  height: 133.84px;
}

.oja-order-dashboard .card .order-details {
  margin-left: 47px;
}

.oja-order-dashboard .card .order-details p {
  margin-bottom: 7px;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #231f20;
}

.oja-order-dashboard .card .order-btn {
  border: 2px solid #6e449b;
  border-radius: 8px;
  width: 113px !important;
  margin-top: 27px;
  padding: 7px 25px;
  background-color: transparent;
  color: #6e449b;
}

.oja-order-dashboard .card .order-btn:active {
  background: #ffde17;
  border: 2px solid #ffde17;
}

/* DASHBOARD ORDER STYLES STOPS HERE */

/* DASHBOARD ORDER DETAILS STYLES STARTS HERE */

.oja-order-details-dashboard a {
  color: #000;
}

.oja-order-details-dashboard .card {
  max-width: 884px;
  background: #fbf8ff;
  border: 1px solid rgba(110, 68, 155, 0.3);
  border-radius: 8px;
}

.oja-order-details-dashboard .order-items .card .card-body {
  text-align: center;
}

.oja-order-details-dashboard .card .form-group .form-control {
  border: 1px solid #6e449b;
  border-radius: 8px;
  width: 440px;
}

.oja-order-details-dashboard h2 {
  font-size: 18px;
}

.oja-order-details-dashboard .order-del {
  max-width: 880px;
  text-align: end;
}

.oja-order-details-dashboard .order-details-btn {
  background-color: #d21c1c !important;
  color: #ffffff;
  border-radius: 8px;
  padding: 7px 32px;
  border: none;
  width: 114px !important;
  height: 38px;
}

/* DASHBOARD ORDER DETAILS STYLES STOPS HERE */

/* DASHBOARD CATEGORY STYLES STARTS HERE */

.oja-category-dashboard .oja-row {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  row-gap: 64px;
  column-gap: 20px;
}

.oja-category-dashboard .card {
  filter: drop-shadow(0px 1.38722px 13.8722px rgba(0, 0, 0, 0.25));
  border: 1.38722px solid #6e449b;
  border-radius: 5.54887px;
  height: 99.33px;
}

.oja-category-dashboard .card .dropdown {
  margin-left: auto !important;
}

.oja-category-dashboard .card .category-name {
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 30px;
  color: #000000;
}

.oja-category-dashboard .card .category-name:hover {
  text-decoration: underline !important;
}
.oja-category-dashboard .card .category-name.special:hover {
  text-decoration: none !important;
}

.oja-category-dashboard .add-category {
  text-align: center;
}

.oja-category-dashboard .add-category img {
  margin-left: 8px;
}

.oja-category-dashboard .add-category p {
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 140%;
  color: #6e449b;
}

/* DASHBOARD CATEGORY STYLES STOPS HERE */

/* DASHBOARD ADD CATEGORY STYLES STARTS HERE */
.modal-content {
  width: 550px;
}
.modal-header.add-category-header {
  align-self: normal;
}
.modal-header .add-category-title {
  color: #6e449b !important;
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 30px;
}

.oja-edit-product-dashboard .add-category-image {
  height: 100px;
  width: 150px;
  cursor: pointer;
  object-fit: contain;
}

.add-category-image {
  height: 150px;
  width: 150px;
  cursor: pointer;
  object-fit: contain;
}
.view-profile-img {
  height: 150px;
  width: 150px;
  object-fit: fill;
}

.pac-container {
  z-index: 11000;
}

.modal-body.add-category-body {
  text-align: left;
  /* padding: 20px; */
}

.modal-body.add-category-body .img-con {
  text-align: center;
}

.modal-body.add-category-body .formik-input {
  border: 1px solid #6e449b !important;
  border-radius: 8px;
  width: 100%;
  height: 48px;
}
.modal-body.add-category-body.coupon .formik-input {
  border: 1px solid #6e449b !important;
  border-radius: 8px;
  width: 100%;
  height: 38px;
}
.modal-body.add-category-body .formik-input:focus {
  border-color: #6e449b !important;
  border: 1px solid #6e449b !important;
}

.modal-body.add-category-body .formik-input::placeholder {
  color: rgba(110, 68, 155, 0.3);
}

.modal-body.add-category-body .is-invalid,
.is-invalid:focus {
  border-color: red !important;
}

.modal-body.add-category-body .error {
  color: red;
  font-size: 12px;
}

.modal-body.add-category-body textarea {
  border-radius: 8px;
  border: 1px solid #6e449b !important;
}

.modal-body.add-category-body.banner .basic-multi-select .select__control {
  border: 1px solid #6e449b !important;
  border-radius: 8px !important;
}
.modal-body.add-category-body .basic-multi-select .select__control {
  border: 1px solid #6e449b !important;
  border-radius: 8px !important;
  height: 54px auto;
}

.add-category-footer {
  text-align: end !important;
}

.add-category-footer button:disabled {
  cursor: not-allowed !important;
}

.add-category-footer .oja-btn {
  padding: 7px 32px;
  width: 122px !important;
  height: 38px;
  background: #ffde17 !important;
  border-radius: 8px;
  border: none;
  color: #6e449b !important;
}

.oja-btn {
  padding: 7px 32px;
  width: 122px !important;
  height: 38px;
  background: #ffde17 !important;
  border-radius: 8px;
  border: none;
  color: #6e449b !important;
}

.modal-body.add-category-body .chrome-picker {
  width: 100% !important;
}

.modal-body.add-category-body .chrome-picker > div:nth-child(1) {
  padding-bottom: 25% !important;
}

/* DASHBOARD ADD CATEGORY STYLES STOPS HERE */

/* DASHBOARD PRODUCT STYLES STARTS HERE */

.oja-product-dashboard h1 {
  font-family: "Clash Display";
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 30px;
  letter-spacing: -0.02em;
  color: #6e449b;
  margin-bottom: 28px;
}

.oja-product-dashboard a p {
  color: #6e449b;
}

.oja-product-dashboard .oja-row {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  row-gap: 64px;
  column-gap: 20px;
}

.oja-product-dashboard .card {
  border: 1.38722px solid #6e449b;
  border-radius: 5.54887px;
  width: 131.56px;
}

.oja-product-dashboard .dropdown {
  position: relative;
  top: -124px;
  left: 82px;
}

.oja-product-dashboard .dropdown img:hover {
  color: #f9971e;
}

.oja-product-dashboard .card h4 {
  font-family: "Clash Display";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  color: #0634d9;
  margin-top: 16.2px;
}

.oja-product-dashboard .card .price {
  font-family: "Clash Display";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  margin-bottom: 6.42px;
}

.oja-product-dashboard .card .quantity {
  font-family: "Clash Display";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 140%;
  text-align: center;
}

.oja-product-dashboard .add-product {
  text-align: center;
  margin-top: 40px;
}

/* DASHBOARD PRODUCT STYLES STOPS HERE */

/* DASHBOARD INVENTORY STYLES STARTS HERE */

.oja-inventory-dashboard .top {
  background: #f8f2ff;
  padding: 20px;
  margin-left: -33px;
  margin-right: -33px;
}

.oja-inventory-dashboard .top h2 {
  font-family: "Clash Display";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color: #231f20;
}

.oja-inventory-dashboard .top .top-links {
  position: relative;
  width: 241px;
  height: 96px;
  border: 1px solid #6e449b;
  border-radius: 8px;
  text-align: center;
  color: #231f20;
}

.oja-inventory-dashboard .top .top-links:hover {
  background-color: #6e449b;
  color: #fff !important;
}

.oja-inventory-dashboard .top .top-links p {
  font-family: "Clash Display";
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 30px;
  margin-top: 10px;
}

.oja-inventory-dashboard .top .top-links img {
  position: absolute;
  bottom: -5px;
}

/* DASHBOARD INVENTORY STYLES STOPS HERE */

/* DASHBOARD TRANSACTION STYLES STARTS HERE */

.oja-transaction-dashboard .card {
  border: none;
  border-radius: 4px;
  background: #f4ebf9;
}
.oja-transaction-dashboard .report-actions-container {
  margin-bottom: 0;
}
.oja-transaction-dashboard .reports-filter {
  width: fit-content;
}
.oja-transaction-dashboard .reports-filter .report-tab {
  gap: 16px;
}

/* DASHBOARD TRANSACTION STYLES STOPS HERE */

/* DASHBOARD PAYMENT STYLES STARTS HERE */
.oja-payment-dashboard .card {
  background: #fbf8ff;
  box-shadow: 0px 1.28443px 5.77994px 0.642216px rgba(0, 0, 0, 0.25);
  width: 429px;
  margin-top: 60px;
}

.oja-payment-dashboard .card.bank-logo {
  background: #ffffff;
  box-shadow: 0px 1.28443px 5.77994px 0.642216px rgba(0, 0, 0, 0.25);
  width: 105.97px;
  margin-top: 40px;
}

.oja-payment-dashboard .box {
  background: #ffffff;
  box-shadow: 0px 1.28443px 5.77994px 0.642216px rgba(0, 0, 0, 0.25);
  width: 105.97px;
  height: 49.45px;
  text-align: center;
  margin-top: auto;
  margin-top: 40px;
}

/* DASHBOARD PAYMENT STYLES STOPS HERE */

/* DASHBOARD DATA ANALYTICS STYLES STARTS HERE */
.oja-data-analytics-dashboard .box {
  background: #fbf8ff;
  border-radius: 10px;
  width: 446px;
  height: 93px;
}

.oja-data-analytics-dashboard .oja-row {
  display: grid;
  grid-template-columns: 1fr 1fr;
  row-gap: 64px;
  column-gap: 20px;
}

.oja-data-analytics-dashboard img {
  margin-bottom: 15px;
}

/* DASHBOARD DATA ANALYTICS STYLES STOPS HERE */

/* DASHBOARD SETTINGS STYLES STARTS HERE */
.oja-setting-dashboard .oja-row {
  display: grid;
  grid-template-columns: 1fr 1fr;
  row-gap: 64px;
  column-gap: 20px;
}

.oja-setting-dashboard .box {
  background: #fbf8ff;
  border-radius: 10px;
  width: 446px;
}

.oja-setting-dashboard .title {
  color: #000;
  font-size: 20px;
}

.oja-setting-dashboard p {
  color: #000 !important;
}

/* toggle.css */

.toggle-switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

.toggle-slider {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 34px;
  background-color: red;
  transition: 0.4s;
}

.toggle-slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  border-radius: 50%;
  background-color: #fff;
  transition: 0.4s;
}

.toggle-input {
  opacity: 0;
  width: 0;
  height: 0;
}

.toggle-input:checked + .toggle-slider {
  background-color: #6e449b;
}

.toggle-input:checked + .toggle-slider:before {
  transform: translateX(26px);
}

/*=============================================
=            reset password            =
=============================================*/

.reset-form {
  width: 500px;
  /* border: 1px solid #6e449b;
  padding: 10px;
  border-radius: 12px; */
}

.reset-form button {
  background: #6e449b;
  color: white;
}

.reset-form button:hover {
  background: #6e449b !important;
  color: white !important;
}

/* DASHBOARD SETTINGS STYLES STOPS HERE */

/* Ojapay filter */
.filter-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  background-color: #f5f6fa;
  border-radius: 8px;
  margin-bottom: 20px;
}

.date-range {
  display: flex;
}

.range-btn {
  padding: 10px 15px;
  margin-right: 10px;
  background-color: transparent;
  border: none;
  cursor: pointer;
  transition: all 0.3s ease;
  font-weight: 500;
  color: #333;
}

.range-btn.active {
  background-color: white;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-weight: 600;
}

.custom-date-bar {
  display: flex;
  align-items: center;
  /* background-color: #fff; */
  padding: 8px 15px;
  border-radius: 8px;
  /* box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1); */
  width: auto;
  position: relative;
}

.filter-icon {
  margin-right: 10px;
  cursor: pointer;
  font-size: 16px;
}

.date-range-display {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-grow: 1;
  cursor: pointer;
}

.date-range-display span {
  margin: 0 5px;
  font-size: 14px;
  color: #333;
}

.dropdown-arrow {
  margin-left: 10px;
  transition: transform 0.3s ease;
  font-size: 12px;
}

.dropdown-arrow.open {
  transform: rotate(180deg);
}

.custom-dropdown {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.1);
  padding: 15px;
  z-index: 1000;
}

.dropdown-section {
  margin-bottom: 10px;
}

.dropdown-label {
  display: block;
  font-size: 12px;
  color: #555;
  margin-bottom: 5px;
}

.date-select {
  width: 100%;
  padding: 8px 10px;
  font-size: 14px;
  border: 1px solid #ddd;
  border-radius: 4px;
  background-color: #f9f9f9;
  cursor: pointer;
}

.date-select:hover {
  background-color: #eee;
}

.custom-dropdown:last-child {
  margin-bottom: 0;
}

/* Container for both cards */
.top-cards-container {
  display: flex;

  gap: 20px;
  margin-bottom: 20px;
}

/* Individual card styling */
.top-card {
  background-color: #fff;
  padding: 20px;
  border-radius: 12px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.05);
  width: 300px; /* Adjust width as needed */
}

.top-card-content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.top-card-title {
  font-size: 16px;
  font-weight: 500;
  color: #666;
  margin-bottom: 10px;
}

.top-card-value {
  font-size: 32px;
  font-weight: 600;
  color: #333;
}

.top-card-footer {
  margin-top: 15px;
  display: flex;
  align-items: center;
}

.percentage {
  font-size: 16px;
  color: #24b47e;
  display: flex;
  align-items: center;
}

.percentage::before {
  content: "▲"; /* Up arrow for percentage change */
  color: #24b47e;
  margin-right: 5px;
}
.sales-main-container {
  margin-top: 80px;
}
/* Container for all the sales cards */
.sales-cards-container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 20px;
}
.sales-card {
  background-color: #fff;
  padding: 20px;
  border-radius: 12px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.05);
  min-height: 120px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
/* Individual sales card styling */
.sales-card-top {
  display: flex;
  align-items: start;
  justify-content: space-between;

  cursor: pointer;
}

/* .sales-card:hover {
  transform: translateY(-4px);
  box-shadow: 0 6px 16px rgba(0, 0, 0, 0.1);
} */

.sales-card-title {
  font-size: 16px;
  font-weight: 500;
  color: #6e449b;
  text-align: center;
}
.sales-card-vertical-dots {
  position: relative;
}

/* Container styles for the top bar */
/* Container styles for the top bar */
.topbar-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 2rem;
  /* background-color: #f5f5f5; */
  border-bottom: 1px solid #ddd;
  margin-bottom: 20px;
}

/* Title styling for the report type */
.topbar-title {
  font-size: 24px;
  font-weight: bold;
  color: #653bad;
}

/* Container for the export buttons */
.topbar-actions {
  display: flex;
  gap: 10px;
}

/* Styling for each export button */
.export-button {
  padding: 10px 20px;
  border: 1px solid black; /* Black border for all buttons */
  border-radius: 5px;
  font-size: 14px;
  display: flex;
  align-items: center;
  gap: 10px;
  cursor: pointer;
  background-color: #ffffff; /* Set the same background color for all */
  color: black; /* Set the text color */
  font-weight: 500; /* Optional: For better visibility */
}

/* Hover effects */
.export-button:hover {
  background-color: #eaeaea; /* Light gray background on hover */
  border-color: black;
}
.email-dropdown-container {
  position: relative;
}

.dropdown-content {
  position: absolute;
  top: 50px; /* Adjust based on button height */
  right: 0;
  background-color: white;
  border: 1px solid #e0e0e0;
  border-radius: 5px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 10px 10px;
  width: 150px;
  z-index: 99;
}

.dropdown-content p {
  padding: 2px;
  margin: 0;
  font-size: 12px;
  cursor: pointer;
}

.dropdown-content p:hover {
  background-color: #f9f9f9;
}
/* StatsContainer.css */
.stats-container {
  display: grid;
  grid-template-columns: 1fr 2fr;
  gap: 20px;

  align-items: stretch; /* Ensures both columns stretch to the same height */
}

.left-side {
  padding: 20px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  height: 100%;
  border: 1px solid #e8e8e8;
}

.right-side {
  background-color: #ffffff;
  height: 100%;
  width: 70%;
  height: 100%;
  border: 1px solid #e8e8e8;
  padding: 20px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
}

.total-card.pay-reports-card .card img {
  width: 28px;
  height: 28px;
}
.total-card.pay-reports-card .card .pay-reports-trend {
  margin-top: 8px;
}

.total-card.pay-reports-card .card .pay-reports-trend img {
  width: 16px;
  height: 16px;
}
.total-card.pay-reports-card .card .pay-reports-trend {
  display: flex;
  gap: 8px;
  color: #00b69b;
  font-size: 12px;
  font-weight: 500;
}
.total-card.pay-reports-card .card {
  display: flex;
  gap: 12px;
  flex-direction: row;
}
.total-card.pay-reports-card .card .down-trend {
  color: #f93c65;
}

.revenue-category {
  background-color: white;
  /* padding: 15px; */
  /* border-radius: 10px; */
  border: 1px solid #e8e8e8;
  overflow-y: auto;
  max-height: 300px;
  margin-top: 20px;
}

.revenue-table {
  width: 100%;
  border-collapse: collapse;
}

.revenue-table th,
.revenue-table td {
  padding: 12px;
  text-align: left;
  border-bottom: 1px solid #ddd;
}
.pay-reports-details .report-title {
  font-size: 24px;
}

.revenue-table th {
  font-weight: bold;
  color: #653bad;
  font-size: 14px;
  text-align: left;
  position: sticky;
  top: 0; /* Make the header sticky at the top */
  background-color: white; /* Ensures header background doesn't get lost */
  z-index: 1; /* Ensures header stays on top while scrolling */
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.1); /* Optional: Add a subtle shadow for better visibility */
}

.revenue-table td {
  font-size: 16px;
}

.revenue-table tr:hover {
  background-color: #f9f9f9;
}

.total-card {
  display: flex;
  flex-direction: column;
  gap: 20px;
  flex-grow: 1; /* Allows the left side to fill the available height */
}

.card {
  padding: 15px;
  border-radius: 10px;
  border: none;
  flex-grow: 1; /* Ensures the card expands to fill space */
  border-bottom: 1px solid #e0e0e0; /* Divider below each card */
}

/* Remove border from the last card to avoid an extra divider at the bottom */
.card:last-child {
  border-bottom: none;
}

.card-title {
  font-size: 16px;
  color: #888;
}

h2 {
  font-size: 24px;
  font-weight: bold;
  margin: 10px 0 0;
}

.orders-section {
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  border: 1px solid #e8e8e8;
  overflow-x: auto;
  margin-top: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.orders-title {
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 20px;
  color: #333;
}

.orders-table {
  width: 100%;
  border-collapse: collapse;
}

.orders-table th {
  text-align: left;
  padding: 12px;
  background-color: #f9f9f9;

  font-size: 12px;

  border-bottom: 2px solid #ddd;
}

.orders-table td {
  padding: 12px;
  border-bottom: 1px solid #ddd;
  font-size: 14px;
  color: #333;
}

.orders-table tr:hover {
  background-color: #f9f9f9;
}

.status-success {
  color: #28a745;
}

.status-failed {
  color: #dc3545;
}

@media (max-width: 768px) {
  .orders-table th,
  .orders-table td {
    font-size: 14px;
    padding: 10px;
  }
}

/* Black Overlay */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5);
  z-index: 999;
  transition: opacity 0.3s ease;
}

/* Modal Container */
.modal-container {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: auto; /* Set the width to auto so it adjusts to content */
  max-width: 700px; /* Maximum width of 700px */
  min-width: 300px; /* Optional: Set a minimum width so it's not too small */
  background-color: white;
  border-radius: 10px;
  padding: 20px;
  z-index: 9999;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s ease, transform 0.3s ease; /* Added transitions */
  box-sizing: border-box; /* Ensure padding is included in the width */
}

/* Show modal with animation */
.modal-container.open {
  opacity: 1;
  visibility: visible;
}

.modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.modal-header h2 {
  margin: 0;
  font-size: 20px;
  color: #000;
}

.close-modal {
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
}

.modal-body {
  margin-top: 20px;
}

.modal-body p {
  margin-bottom: 20px;
  text-align: left;
  color: black;
}

.file-type-options {
  display: flex;
  gap: 12px;
}

.file-type-btn {
  padding: 4px 12px;
  border: 1px solid #e0e0e0;
  background-color: white;
  cursor: pointer;
  font-size: 12px;
  border-radius: 4px;
}

input[type="email"] {
  width: 100%;
  padding: 12px;
  margin-top: 15px;
  border: 1px solid #e0e0e0;
  border-radius: 5px;
}

.modal-actions {
  display: flex;
  gap: 20px;
  justify-content: center;
  margin-top: 20px;
}

.modal-actions button {
  padding: 10px 15px;
  background-color: #6e449b;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}
.tertiary {
  background-color: transparent !important;
  border: 1px solid #6e449b !important;
  color: #6e449b !important;
  font-weight: 600;
}
/* Centered content for success messages */
.center p {
  text-align: center;
  max-width: 70%;
  width: auto;
  margin-left: auto;
  margin-right: auto;
}
.center span {
  color: #b6b6b6;
}
.center h3 {
  font-size: 20px;
  color: #000;
}
.schedule-options {
  display: flex;
  gap: 10px;
  background: #f5f6fa;
  width: 200px;
}

.schedule-options-container {
  margin-top: 10px;
}

.schedule-options button {
  padding: 4px 10px;
  background: transparent;
  border: none;
  cursor: pointer;
  font-size: 12px;
  border-radius: 5px;
  transition: background 0.2s ease-in-out;
}

.schedule-options button.active {
  background: white;
  border: 1px solid #ccc;
}

.back-to-normal {
  margin-top: 10px;
  display: flex;
  justify-content: start;
  color: #6e449b;
  cursor: pointer;
  background: none;
  border: none;
  font-size: 12px;
}

.back-to-normal:hover {
  text-decoration: underline;
}

.modal-footer-btn {
  display: block;
  width: 100%;
  padding: 12px;
  background-color: #653bad;
  color: white;
  border: none;
  border-radius: 5px;
  margin-top: 20px;
}
.error-input {
  border: 1px solid red;
}

.error-message {
  color: red;
  font-size: 12px;
}
.close-btn {
  margin-top: 20px;
  background: #6e449b;
  color: white;
  border: none;
  border-radius: 4px;
  padding: 4px 50px;
}

/* NO EVENTS */

.event-header-container {
  display: flex;
  justify-content: space-between;
  text-transform: capitalize;
  margin-bottom: 57px;
}
.event-header-container a {
  width: fit-content;
}
.event-header-container button {
  border: 1px solid #6e7c87;
  border-radius: 8px;
  background: transparent;
  padding: 8px 8.5px;
}
.event-header {
  font-size: 36px;
  color: #6e449a;
}

.no-event {
  width: 100%;
  border: 1px solid #cbcbcb;
  height: 427px;
  border-radius: 16px;
  background: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.no-event > h3 {
  font-size: 36px;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.24);
}

/* EVENTS */

.summary-cards {
  display: flex;
  column-gap: 24px;
  margin-bottom: 4px;
  max-width: 880px;
  margin-top: 13px;
}
.summary-cards .card {
  background-color: white;
  padding: 24px 28px;
  border-radius: 16px;
  box-shadow: 6px 6px 54px 0 rgba(0, 0, 0, 0.08);
  border: 1px solid #e8e8e8;
  flex: 1;
}
.summary-cards .card h3 {
  font-size: 12px;
  color: #666;
  margin-bottom: 8px;
}
.summary-cards .card .value {
  font-size: 24px;
  font-weight: 600;
  margin-bottom: 32px;
}
.summary-cards .card .change {
  color: green;
  display: flex;
  gap: 8px;
  align-items: center;
  margin-bottom: 0;
  font-size: 12px;
}

.events .search-bar {
  border: 1px solid #eaeaea;
  border-radius: 12px;
  padding: 8px;
  margin-bottom: 31px;
  width: 403px;
  background-color: white;
  color: #000;
}
.events .search-bar input {
  border: none;
  outline: none;
  width: 100%;
  background-color: transparent;
}

.events h3 {
  font-size: 20px;
  font-weight: 600;
  color: #252c32;
  margin-bottom: 12px;
}
.events table {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0px 16px;
  margin-bottom: 36px;
  color: #252c32;
}
.events th,
.events td {
  text-align: left;
  padding: 8px 16px;
}
.events th {
  font-weight: 600;
  font-size: 12px;
  border-bottom: 4px solid #e5e9eb;
}
.events tr.ongoing {
  background-color: #e5e9eb;
}
.events tr.sold-out {
  background-color: #f2e7ff;
}
.events tr.draft {
  background-color: #f2e7ff;
}

.events tr {
  font-size: 14px;
  font-weight: 500;
}
.events .vertical-dots {
  float: right;
  padding: 8px;
}

.pagination {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #f5f6fa;
  margin-top: 20px;
  padding: 4px 8px 4px 24px;
}
.pagination-overview {
  color: #6e7c87;
  font-size: 12px;
  font-weight: 500;
}
.pagination-controls {
  display: flex;
}
.pagination-controls button {
  border: 1px solid #e8e8e8;
  padding: 12px 8px;
  font-size: 14px;
  font-weight: 500;
}
.pagination-controls button.active {
  background-color: #891bb2;
  color: white;
}

/* CREATE EVENT BUTTON */
.create-event-btn {
  position: relative;
  width: 100%;
  padding: 0;
}
.event-type-btn-container {
  display: flex;
  gap: 32px;
  padding: 14px 16px;
  width: fit-content;
  background-color: #f5f6fa;
  position: absolute;
  right: 0;
  z-index: 3;
  border-radius: 12px;
  /* bottom: -10px; */
}
.event-type-backdrop {
  position: fixed;
  inset: 0;
  background: rgba(110, 68, 154, 0.25);
  display: block;
  content: "";
  width: 100%;
  height: 100%;
  z-index: 2;
}
.event-type-btn-container p {
  margin-bottom: 0;
}

.event-type-btn {
  width: 263px;
  border-radius: 12px;
  background-color: white;
  padding: 8px;
  display: flex;
  column-gap: 16px;
  color: #000000;
}

.event-type-btn-icon-container {
  border: 1px solid #e8e8e8;
  border-radius: 8px;
  padding: 12px;
  height: fit-content;
}
.event-type-btn-text-header {
  font-weight: bold;
  margin-bottom: 4px;
}
.event-type-btn-text-desc {
  font-size: 12px;
}

/* CREATE EVENT */
.create-event-container {
  display: flex;
  width: 100%;
  height: 100%;
  column-gap: 100px;
  position: relative;
}

/* SIDEBAR */
.sidebar-container {
  width: 323px;
  height: fit-content;
  position: sticky;
  top: 100px;
}

.create-event-container .sidebar-container {
  border: 0.4px inset #979797;
  border-radius: 18px;
  background-color: #ffffff;
}

.create-event-container .go-back {
  color: #5a0b77;
  font-weight: 500;
  display: flex;
  column-gap: 30px;
  width: 100%;
  padding: 20px 24px;
  border: 0;
  border-bottom: 0.4px solid #979797;
  background-color: transparent;
  align-items: center;
}

.create-event-container .steps-container {
  padding: 52px 24px;
}
.create-event-container .steps-container p {
  font-weight: 500;
  color: #5a0b77;
}
.create-event-step-container {
  padding-left: 0;
  background: #ffffff;
}
.create-event-step {
  display: flex;
  column-gap: 13px;
  align-items: center;
  color: #5a0b77;
  padding: 16px 0;
}
.create-event-step label {
  font-weight: 500;
}
.create-event-step.active label {
  font-weight: 600;
}
.create-event-step-radio {
  accent-color: #5a0b77;
  width: 18px;
  height: 18px;
}

.content-container {
  width: 737px;
}

/* CREATE EVENT PAGE */
.content-container h3 {
  font-size: 28px;
  color: #5a0b77;
  font-weight: 600;
  margin-bottom: 20px;
}

.content-container .content-label {
  font-size: 500;
  color: #5a0b77;
  margin-bottom: 16px;
}

.event-selection-container {
  border: 0.4px solid #979797;
  padding: 18px 16px;
  display: flex;
  justify-content: space-between;
  border-radius: 8px;
  background-color: #ffffff;
}

.option-selector-container .event-selection-value:hover {
  cursor: pointer;
}

.event-selection-value {
  color: rgba(0, 0, 0, 0.77);
  font-weight: 500;
  text-transform: capitalize;
  padding-left: 8px;
}

.option-selector-container .event-selection-value {
  pointer-events: none;
}

.kind-of-event {
  margin-bottom: 24px;
  position: relative;
}

.event-selection-chevron-container {
  border-left: 1px solid rgba(130, 130, 130, 0.74);
  padding-left: 9px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.event-sub-header {
  background: #f2e7ff;
  padding: 10px 0;
  border: 1px solid #f2e7ff;
  border-radius: 8px;
  margin-bottom: 28px;
}
.event-sub-header p {
  color: #5a0b77;
  text-align: center;
  font-weight: 700;
  font-size: 12px;
  margin-bottom: 0;
}

.create-event-label {
  color: #6e449a;
  font-weight: 500;
  margin-bottom: 16px;
}

.upload-image-container {
  border: 1px solid #979797;
  border-radius: 18px;
  height: 175px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  row-gap: 23px;
  margin-bottom: 28px;
  position: relative;
  cursor: pointer;
}

.upload-image-container.dragging {
  background-color: #f5f5f5;
  border: 2px dashed #5a0b77;
}

.upload-image-container .text {
  color: rgba(0, 0, 0, 0.5);
  font-weight: 500;
}

.upload-image-container .text span {
  color: #5a0b77;
  font-weight: bold;
}

.file-input {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  cursor: pointer;
}

.image-preview-container {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.image-preview {
  border-radius: 18px;
  object-fit: cover;
  height: 100px;
}

.remove-btn {
  margin-top: 10px;
  color: red;
  border: none;
  background-color: transparent;
  cursor: pointer;
  font-size: 14px;
  justify-self: end;
}

.remove-btn-x {
  font-size: 16px;
  font-weight: 800;
}

.remove-btn:hover {
  opacity: 0.9;
}

.event-selection-container input::placeholder,
.event-selection-container textarea::placeholder {
  color: rgba(0, 0, 0, 0.2);
}
.event-selection-container input,
.event-selection-container textarea {
  font-weight: 500;

  border: 0;
  width: 100%;
  background-color: #ffffff;
  outline: none;
  resize: none;
}
.event-selection-container textarea {
  height: 96px;
}

.create-event-label-container {
  display: flex;
  justify-content: space-between;
}

.field-container {
  margin-bottom: 28px;
  position: relative;
}

.type-of-event {
  display: flex;
  column-gap: 14px;
}

.type-of-event button {
  border: 0.8px solid #6e7c87;
  padding: 16px 0;
  width: 200px;
  height: fit-content;
  border-radius: 8px;
  font-weight: 500;
  color: #292929;
  background-color: #f5f6fa;
}

.type-of-event button.active {
  background-color: #f2e7ff;
  border: 0.8px solid #5a0b77;
  color: #5a0b77;
}

.map-btn {
  font-weight: 500;
  padding: 14px 20px;
  border-radius: 4px;
  cursor: pointer;
  color: rgba(130, 130, 130, 0.64);
  border: 0.4px solid rgba(130, 130, 130, 0.64);
  display: flex;
  height: fit-content;
  column-gap: 8px;
}

.location-input-field-group {
  display: flex;
  align-items: center;
  column-gap: 14px;
}
.location-input-field-group .event-selection-container {
  width: 60%;
}
.date-input-group input {
  width: 85%;
}
.event-selection-container.date-input-group {
  padding: 16px;
}
.start-input-group {
  display: flex;
  justify-content: space-between;
}

.start-input-group .field-container {
  width: 47%;
}

.event-button-group {
  display: flex;
  column-gap: 24px;
  justify-content: center;
}

.event-form-btn {
  padding: 16px 0;
  width: 200px;
  height: fit-content;
  border-radius: 8px;
  color: #6e449a;
  background-color: #f2e7ff;
  outline: none;
  border: 0;
}

.event-form-btn-next {
  background-color: #891bb2;
  color: white;
  font-weight: 600;
}

.event-form-btn-next:disabled {
  opacity: 0.24;
}

.radio-option-container {
  width: 47%;
}

.radio-option-container label {
  margin-bottom: 0;
}
.radio-option-container .create-event-step {
  padding-top: 0;
}
.radio-option-container .field-container {
  width: 100%;
}
.radio-option {
  display: flex;
  justify-content: space-between;
}

.event-description-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.undo-redo-buttons {
  display: flex;
  gap: 10px;
}

.undo-redo-buttons button {
  background: none;
  border: none;
  cursor: pointer;
  padding: 0;
}

.undo-redo-buttons button img {
  width: 24px;
  height: 24px;
}

.undo-redo-buttons button:disabled img {
  opacity: 0.5;
  cursor: not-allowed;
}

.event-link-img-container {
  border-right: 1px solid rgba(130, 130, 130, 0.74);
  padding-right: 9px;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* NO TICKET */
.no-ticket-container {
  padding: 40px 0;
  width: fit-content;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  color: #5a0b77;
  margin: auto;
}
.no-ticket-container h3 {
  font-size: 36px;
  font-weight: bold;
}
.no-ticket-container p {
  font-weight: 500;
  margin-bottom: 41px;
}

.no-ticket-container button {
  background-color: #f2e7ff;
  border-radius: 8px;
  padding: 16px 0;
  width: 90%;
  font-weight: 600;
  border: 0;
  color: #891bb2;
}

/* CREATE TICKET */
.add-ticket-desc {
  font-weight: 500;
  color: #6e449a;
}

.add-ticket-form {
  margin-top: 36px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}

.ticket-desc-container .event-selection-container {
  border: 1px solid #e8e8e8;
}
.ticket-desc-container textarea {
  height: 72px;
  resize: none;
}

.ticket-stock-container {
  display: flex;
  justify-content: space-between;
}
.ticket-stock-container .field-container {
  width: 47%;
}

.add-ticket-form .event-button-group {
  margin-top: 36px;
}
.ticket-character-count {
  font-weight: 500;
  color: rgba(130, 130, 130, 0.64);
}

/* CATEGORY SELECTOR */

.selected-category-container {
  display: flex;
  gap: 16px;
  min-height: 46px;
  width: 100%;
  flex-wrap: wrap;
}

.selected-categories {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
}

.selected-category {
  display: flex;
  align-items: center;
  background-color: #f2e7ff;

  padding: 10px 12px;
  border-radius: 8px;
}

.remove-category-btn {
  background: none;
  border: none;
  color: white;
  margin-left: 8px;
  cursor: pointer;
}

.category-modal {
  background-color: white;
  padding: 20px 0;
  border-radius: 12px;
  box-shadow: 6px 6px 54px 0px rgba(0, 0, 0, 0.08);

  max-width: 540px;
  position: absolute;
  /* bottom: 0; */
  left: 0;
  z-index: 3;
}

.field-container h3 {
  font-size: 12px;
  margin-bottom: 16px;
  color: rgba(0, 0, 0, 0.77);
  padding: 0 20px;
}

.category-list {
  display: flex;
  flex-wrap: wrap;
  gap: 12px;
  justify-content: center;
  border-bottom: 0.4px solid #979797;
  padding: 0 20px 10px;
}

.category-btn {
  border: 1px solid #979797;
  padding: 8px 16px;
  border-radius: 24px;
  background: none;
  color: #979797;
  cursor: pointer;
  transition: background-color 0.2s ease, color 0.2s ease;
}

.category-btn.active {
  background-color: #891bb2;
  color: white;
}

.category-btn:hover {
  background-color: #891bb2;
  color: white;
}

.category-note {
  font-size: 12px;
  text-align: center;
  margin-top: 16px;
  color: #888;
}

.apply-btn {
  display: block;
  margin: 20px auto 0;
  padding: 10px 20px;
  background-color: #891bb2;
  color: white;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  font-size: 16px;
  margin: 0 auto;
}

.apply-btn:hover {
  background-color: #891bb2;
}

.event-selection-container.categories {
  padding: 10px 16px;
}

/* Option selector */

.option-list-container {
  width: 100%;
  max-height: 452px;
  border-radius: 8px;
  box-shadow: -5 3 24 2 rgba(0, 0, 0, 0.02);
  padding: 20px 0;
  overflow-y: auto;
  z-index: 45;
  position: absolute;
  background: white;
  scrollbar-width: 1px;
}
.option-list-container li {
  padding: 10px 16px;
}
.option-list-container li:hover {
  cursor: pointer;
}
.field-container.ticket-price {
  width: 60%;
}

/* YOUR TICKETS */

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;
}
.add-ticket-btn {
  background-color: #891bb2;
  color: white;
  padding: 12px 0;
  border: none;
  cursor: pointer;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  font-weight: 600;
  width: 212px;
}

.ticket-list-table {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0px 16px;
  margin-bottom: 36px;
  color: #252c32;
}

.ticket-list-table th,
.ticket-list-table td {
  text-align: left;
  padding: 8px 16px;
}
.ticket-list-table tr.ongoing {
  background: #f2e7ff;
}
.ticket-list-table th {
  font-weight: 600;
  font-size: 14px;
  border-bottom: 4px solid #e5e9eb;
}
.ticket-list-table tr {
  font-weight: 500;
  font-size: 14px;
}

.ticket-list-table .vertical-dots {
  float: right;
  padding: 8px;
}

.save-button-container {
  margin-top: 105px;
  display: flex;
  justify-content: center;
}

/* NO Product */
.no-product-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  max-width: 540px;
  margin: 50px auto;
}
.no-product-header {
  font-weight: 500;
  color: #6e449a;
  margin-bottom: 4px;
}
.no-product-header h3 {
  font-size: 36px;
  margin-bottom: 4px;
}
.no-product-header p {
  margin-bottom: 41px;
}

.btn {
  margin-top: 38px;
  border: 1px solid #891bb2;
  padding: 16px 0;
  color: #891bb2;
  margin-bottom: 30px;
  margin-bottom: 12px;
  width: 252px;
  display: block;
  font-weight: 600;
}

.btn-skip {
  border: 0;
}

/* ADD PROJECT */
.add-project-img {
  border: 1px solid #e8e8e8;
  border-radius: 8px;
  padding: 6px 10px;
  width: 200px;
  padding: 30px 26px 26px;
  position: relative;
}

.add-project-img .tag {
  background: #000;
  border-radius: 4px;
  padding: 4px 0;
  color: white;
  background: #107c41;
  font-size: 12px;
  font-weight: 600;
  width: 40%;
  top: 5px;
  right: 5px;
  text-align: center;
  position: absolute;
}

.add-project-img img {
  width: 148px;
  margin: auto;
  object-fit: cover;
}

.add-project-desc {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.add-project-desc h4 {
  font-weight: 600;
  color: #6e449a;
  margin-bottom: 8px;
  font-size: 16px;
  margin-top: 16px;
}

.add-project-desc p {
  color: #6e449a;
  font-weight: 500;
  margin-bottom: 0px;
}

.product-item-container {
  width: 200px;
}

/* PUBLISH EVENT */
.event-card-container {
  width: 100%;
  margin: 0 auto;
  padding: 20px;
}

.event-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.event-image {
  flex: 1;
  margin-right: 20px;
}

.event-image img {
  width: 300px;
  /* height: 300px; */
  border-radius: 8px;
}

.event-details {
  flex: 1;
  text-align: left;
}

.event-name {
  font-size: 36px;
  font-weight: bold;
  color: #6b23a7;
  margin-bottom: 12px;
}

.event-date,
.event-time,
.event-type {
  font-size: 12px;
  color: #252c32;
  font-weight: 600;
  margin-bottom: 5px;
  padding: 12px 16px;
  border-bottom: 1px solid #e5e9eb;
}

.event-preview-container {
  color: #891bb2;
  font-weight: 600;
  display: flex;
  gap: 16px;
  margin: 16px 0;
  text-decoration: underline;
}
.event-card-container .published {
  color: #00b69b;
}
.event-preview-container p {
  text-decoration: underline;
  margin-bottom: 0;
}

.event-buttons {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  max-width: 60%;
}

.publish-btn,
.save-draft-btn {
  padding: 10px 20px;
  border-radius: 8px;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
}

.publish-btn {
  background-color: #891bb2;
  color: white;
  border: none;
  margin-right: 10px;
}

.save-draft-btn {
  background-color: transparent;
  color: #891bb2;
  border: 2px solid #891bb2;
}

.publish-btn:hover,
.save-draft-btn:hover {
  opacity: 0.9;
}

/* CANCEL MODAL  */

/* .modal-container {
  position: fixed;
  inset: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000; */
/* background: rgba(110, 68, 154, 0.25); */
/* background-color: #000;
} */

/* .cancel-modal-container {
  position: fixed;
  inset: 0;
  background: rgba(110, 68, 154, 0.25);
  display: block;
  content: "";
  width: 100%;
  height: 100%;
  z-index: 2;
  display: flex;
  justify-content: center;
  align-items: center;
} */

.cancel-modal-content {
  padding: 85px 115px 86px;
  background: white;
  border-radius: 8px;
  display: flex;
  align-items: center;
  flex-direction: column;
  color: #6e449a;
  box-shadow: -5 3 24 3 rgba(0, 0, 0, 0.04);
}

.cancel-modal-content h2 {
  font-size: 36px;
  margin-bottom: 4px;
  font-weight: bold;
}
.cancel-modal-content p {
  margin-bottom: 64px;
  font-weight: 500;
}

/* ELlipsis */
.ellipsis:hover {
  cursor: pointer;
  float: right;
}

.option-list-container.ellipsis-option li {
  padding: 10px 16px;
  font-weight: 500;
}
.option-list-container.ellipsis-option {
  right: 24px;
  padding: 10px;
  width: 118px;
}

/* TRANSACTION TABLE */
.transaction-table-container {
  width: 100%;
  overflow-x: auto;
}

.transaction-table-container table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
}

.transaction-table-container thead {
  font-weight: 600;
  border-bottom: 4px solid #e5e9eb;
}

.transaction-table-container th,
.transaction-table-container td {
  padding: 12px 15px;
  text-align: left;
  font-size: 14px;
  border-bottom: 1px solid #e5e9eb;
}

.transaction-table-container th {
  color: #252c32;
  font-weight: 600;
}

.transaction-table-container td {
  color: #252c32;
}

.transaction-table-container .ellipsis-btn {
  background: none;
  border: none;
  font-size: 20px;
  cursor: pointer;
  color: #666;
}

.transaction-table-container .ellipsis-btn:hover {
  color: #333;
}

.transaction-table-container .search-bar {
  margin-bottom: 20px;
}

.transaction-table-container .search-bar input::placeholder {
  color: rgba(130, 130, 130, 0.74);
}
.transaction-table-container .search-bar input {
  width: 300px;
  padding: 13px 16px;
  border: 1px solid #eaeaea;
  border-radius: 12px;
  font-size: 16px;
  font-size: 14px;
}

.transaction-table-container .tabs-container {
  display: flex;
  margin-bottom: 20px;
  background: #f9f3ff;
  width: fit-content;
}

.transaction-table-container .tab-button {
  padding: 10px 20px;
  background-color: transparent;
  border: none;
  font-size: 16px;
  cursor: pointer;
  color: #292929;
  border-bottom: 2px solid transparent;
  width: 200px;
  font-weight: 500;
}

.transaction-table-container .active-tab {
  border-bottom: 2px solid #5a0b77;
  color: #5a0b77;
  font-weight: 600;
}

.transaction-table-container .table-ellipsis {
  position: relative;
}
.transaction-table-container .tab-button:hover {
  opacity: 0.7;
}

/* order details */
.order-details-container {
  padding: 20px;
  max-width: 800px;
  background-color: #ffffff;
  border-radius: 10px;
  border: 1px solid #e8e8e8;
}

.order-details-container .details-section {
  margin-bottom: 36px;
}

.order-details-container .section-header {
  font-size: 28px;
  font-weight: 500;
  color: #6e449a;
  margin-bottom: 16px;
}

.order-details-container .details-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
}

.order-details-container .details-grid .payment-type {
  font-weight: 600;
  font-size: 20px;
  margin-bottom: 0;
}
.order-details-container .details-grid p {
  font-weight: 500;
  color: #252c32;
}
.order-details-container .details-grid p {
  margin: 0;
  font-size: 16px;
}

.order-details-container .event-details {
  display: flex;
  align-items: center;
}

.order-details-container .order-details-image {
  width: 135px;
  height: 118px;
  margin-right: 24px;
  border-radius: 8px;
  object-fit: cover;
}

.order-details-container .event-status {
  color: #00b69b;
  font-weight: 500;
  margin-bottom: 8px;
  font-size: 12px;
}

.order-details-container .event-details h3 {
  font-size: 18px;
  margin: 0 0 5px 0;
}

.order-details-container .event-details p {
  margin: 0;
  color: #666;
}

.event-venue-date {
  font-size: 12px;
}

.event-details-name {
  font-size: 16px;
  font-weight: 600;
}

.order-actions-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.order-actions-container h2 {
  font-size: 28px;
  font-weight: 600;
  color: #6e449a;
  margin: 0;
}

.action-buttons {
  display: flex;
  gap: 20px;
  margin-bottom: 13px;
}

.download-btn,
.export-btn {
  display: flex;
  align-items: center;
  gap: 16px;
  padding: 16px;
  cursor: pointer;
  border-radius: 8px;
  border: none;
  font-weight: 600;
}

.download-btn {
  background-color: white;
  color: #891bb2;
  border: 2px solid #891bb2;
}

.export-btn {
  background-color: #891bb2;
  color: white;
}

button:hover {
  opacity: 0.9;
}

.order-details-action-btn button {
  border: 0;
  background: transparent;
}
.order-details-action-btn button:disabled {
  opacity: 0.5;
}
.order-details-action-btn button img {
  width: 12px;
  height: 24px;
}
.order-details-action-btn {
  display: flex;
  gap: 26px;
  margin-bottom: 24px;
}

/* PRINT MODAL */
.print-receipt-modal-content {
  background-color: #ffffff;
  padding: 44px;
  border-radius: 24px;
  text-align: center;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  width: 600px;
}

.print-receipt-modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.print-receipt-modal-header h2 {
  font-size: 24px;
  color: #6e449a;
  font-weight: 600;
}

.print-receipt-modal-close-btn {
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
}

.print-receipt-modal-company-name {
  font-size: 20px;
  font-weight: 500;
  color: #6e449a;
  margin-bottom: 28px;
}

.print-receipt-modal-details {
  width: 100%;
  text-align: left;
  margin-bottom: 20px;
}

.print-receipt-modal-details td {
  padding: 8px 0;
  font-size: 14px;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.77);
  border-bottom: 1px solid #e8e8e8;
}

.print-receipt-modal-footer {
  margin-top: 31px;
}

.print-ticket-type,
.print-ticket-name,
.print-ticket-type,
.print-ticket-price,
.print-ticket-total {
  font-weight: 400 !important;
}

.print-ticket-total {
  padding-top: 32px !important;
}

.print-receipt-modal-details td:last-child {
  text-align: right;
}
.export-as-container {
  position: relative;
}

.option-list-container.ellipsis-option.export-as-selector-container {
  right: 0;
}

/* calender modal */
.calendar-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.calendar-backdrop {
  position: absolute;
  width: 100%;
  height: 100%;
  background: rgba(110, 68, 154, 0.25);
}

.calendar-content {
  position: relative;
  padding: 24px;
  border-radius: 16px;
  background: #fbfcff;
  z-index: 1001;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
}

.react-datepicker {
  border: 0 !important;
}
.react-datepicker,
.react-datepicker__header {
  background: #fbfcff !important;
  border-bottom: 0 !important;
}

.react-datepicker__day-names {
  margin-top: 28px;
}

.react-datepicker__current-month {
  color: #141414;
  font-size: 24px !important;
}

/* button */
.react-datepicker__navigation.react-datepicker__navigation--previous,
.react-datepicker__navigation.react-datepicker__navigation--next {
  border-radius: 100%;
  box-shadow: 0 1px 1px 0px rgba(0, 0, 0, 0.09);
  background: white;
  width: 44px;
  height: 44px;
  color: #000 !important;
}

.react-datepicker__navigation-icon.react-datepicker__navigation-icon--previous::before {
  right: -4px;
  top: 10px;
}
.react-datepicker__navigation-icon--next::before {
  left: -3px !important;
  top: 10px !important;
}

.react-datepicker__day--selected,
.react-datepicker__day--in-selecting-range,
.react-datepicker__day--in-range,
.react-datepicker__month-text--selected,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__month-text--in-range,
.react-datepicker__quarter-text--selected,
.react-datepicker__quarter-text--in-selecting-range,
.react-datepicker__quarter-text--in-range,
.react-datepicker__year-text--selected,
.react-datepicker__year-text--in-selecting-range,
.react-datepicker__year-text--in-range {
  background: #0047ff !important;
  color: white !important;
  font-weight: 600 !important;
  border: 2px solid white;
}

.react-datepicker__week div {
  border: 0.5px solid #e5e5e5;
  box-shadow: 0px 4px 12px rgba(0, 14, 51, 0.05);
  border-radius: 6px;
  color: #1f1f1f;
}

.react-datepicker__day-name,
.react-datepicker__day,
.react-datepicker__time-name {
  width: 50px !important;
  height: 44px !important;
  align-content: center;
  font-weight: 500;
  font-size: 16px;
}

.react-datepicker__day--outside-month {
  border: 0 !important;
  box-shadow: none !important;
  color: rgba(0, 23, 84, 0.15) !important  ;
}

/* EventsCategories */
.event-categories-container {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: space-between;
  margin-top: 64px;
}

.event-categories-card {
  background-color: white;
  border-radius: 12px;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
  padding: 20px;
  display: flex;
  cursor: pointer;
  transition: transform 0.2s ease;
  width: calc(33.33% - 20px);
  box-sizing: border-box;
  height: 120px;
}

.event-categories-card:hover {
  transform: translateY(-5px);
}

.event-categories-card-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: fit-content;
}

.event-categories-title {
  font-size: 20px;
  font-weight: 600;
  color: #6e449a;
  margin-bottom: 0;
}
.event-category-details {
  display: flex;
  gap: 24px;
}

.event-categories-icon {
  width: 32px;
  height: 32px;
}

.event-categories-card-options {
  display: flex;
  align-items: center;
}

.event-categories-card-options img {
  width: 20px;
  height: 20px;
  cursor: pointer;
}

.event-category-btn {
  width: fit-content !important;
  padding: 12px 24px;
  height: fit-content !important;
  text-wrap: nowrap;
  border-radius: 8px;
  border-color: #6e7c87;
}

.create-event-categories h3 {
  font-weight: bold;
  font-size: 24px;
  margin-bottom: 64px;
  color: #6e449a;
}

.create-event-categories .event-form-btn.event-form-btn-next:disabled {
  background: #bfbfbf;
  float: right;
  color: white;
}

.create-event-categories .btn-container {
  margin-top: 36px;
  display: flex;
  justify-content: end;
}
li {
  list-style: none;
}

/* Reports */
/* Reports Header */
.report-actions-container {
  display: flex;
  align-items: center;
  gap: 20px;
  margin-bottom: 40px;
  justify-content: space-between;
}

.report-title {
  font-size: 28px;
  font-weight: 600;
  color: #6e449a;
}

.report-actions-buttons {
  display: flex;
  gap: 32px;
}

.report-action-button {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 8px 24px;
  border: 1px solid #d1d1d1;
  background-color: white;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.2s ease, border-color 0.2s ease;
}

.report-action-button:hover {
  background-color: #f0f0f0;
}

.report-icon {
  width: 20px;
  height: 20px;
}
/*Report card Overview  */

.stat-cards-container {
  display: grid;
  grid-template-columns: repeat(5, 1fr); /* 5 columns of equal width */
  gap: 16px; /* Space between cards */
  margin-bottom: 40px;
}

.stat-card {
  background-color: white;
  border-radius: 12px;
  box-shadow: 6px 6px 54px 0 rgba(0, 0, 0, 0.08);
  padding: 28px 24px;
  text-align: left;

  box-sizing: border-box;
  border: 1px solid #e8e8e8;
}
.stat-title {
  font-size: 12px;
}

.stat-value {
  font-size: 28px;
  font-weight: 600;

  margin: 16px 0 32px;
}

.stat-change-container {
  display: flex;
  align-items: center;
  gap: 5px;
}

.change-icon {
  width: 16px;
  height: 16px;
}

.stat-change {
  font-size: 12px;
  font-weight: 500;
}

.stat-change.increase {
  color: #1db954; /* Green for increase */
}

.stat-change.decrease {
  color: #ff4d4f; /* Red for decrease */
}

@media (max-width: 768px) {
  .stat-card {
    width: calc(50% - 20px);
  }
}

@media (max-width: 480px) {
  .stat-card {
    width: 100%;
  }
}

/* Reports Table */
.revenue-event-table-container {
  margin-bottom: 77px;
}
.revenue-event-table-container tr:nth-child(even) td {
  background: #f2e7ff;
}
.revenue-event-table-container td {
  font-weight: 500;
}
.revenue-event-table-container h3 {
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 16px;
  color: rgba(0, 0, 0, 0.77);
}
.revenue-event-table-container table {
  border-collapse: separate;
  border-spacing: 0 16px;
  margin-top: 0;
}
.reports-table .option-list-container.ellipsis-option {
  width: 124px;
  top: 43px;
  text-wrap: nowrap;
}

/* charts */

.report-dashboard-container {
  display: flex;
  gap: 20px;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 20px;
}

.report-dashboard-card {
  background-color: white;
  border-radius: 12px;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
  padding: 20px;
  width: calc(33.33% - 20px); /* Three cards per row */
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
}

.report-title {
  font-family: "Clash Display";
  font-style: normal;
  font-weight: 600;
  font-size: 36px;
  line-height: 30px;
  color: #6e449b;
  margin-bottom: 20px;
}

.stats-container {
  display: flex;
  gap: 2rem;
  font-family: system-ui, -apple-system, sans-serif;
  justify-content: center;
  margin-top: 30px;
}

.custom-table.custom-table-wallet-report {
  border: 1px solid #ececec;
}

.stats-container .stat-group {
  display: flex;
  flex-direction: column;
}

.stats-container .number {
  font-size: 28px;
  font-weight: 600;
  color: #202224;
}

.stats-container .label-container {
  display: flex;
  align-items: center;
  gap: 8px;
}

.stats-container .dot {
  width: 12px;
  height: 12px;
  border-radius: 50%;
}

.stats-container .dot.new {
  background-color: #4880ff;
}

.stats-container .dot.returning {
  background-color: #c0d2f0;
}

.stats-container .label {
  color: #282d32;
  /* font-weight: 500; */
}

.report-top-events-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 15px;
}

.report-top-events-table th,
.report-top-events-table td {
  border-bottom: 1px solid #e5e9eb;
  padding: 8px;
  text-align: left;
}

.report-top-events-table th {
  font-weight: 600;
  font-size: 12px;
  color: #333;
  background: #fbfcff;
  border-bottom-width: 4px;
  padding-top: 12px;
  padding-bottom: 12px;
}

.report-top-events-table td {
  color: #252c32;
  font-weight: 500;
}

.report-top-events-table tr:hover {
  background-color: #f1f1f1;
}

/* Responsive design for smaller screens */
@media (max-width: 768px) {
  .report-dashboard-card {
    width: calc(50% - 20px); /* Two cards per row */
  }
}

@media (max-width: 480px) {
  .report-dashboard-card {
    width: 100%; /* One card per row */
  }
}

/* SalesSummary */
.report-sales-summary-container {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  background-color: #ffffff;
  border-radius: 12px;
  padding: 20px;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
  margin: 20px 0;
}

.report-sales-summary {
  width: 70%;
}

.report-summary-title {
  font-size: 20px;
  font-weight: 600;
  color: rgba(0, 0, 0, 0.77);
  margin-bottom: 20px;
}

.report-summary-table {
  width: 100%;
  border-collapse: collapse;
  max-width: 456px;
}

.report-summary-table td {
  padding: 10px 0;
  border-bottom: 1px solid #e5e9eb;
  font-size: 14px;
  color: #252c32;
  font-weight: 500;
}

.report-download-button {
  background-color: #5a0b77;
  color: white;
  border: none;
  padding: 12px 24px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.report-download-button img {
  margin-right: 16px;
}

.report-download-button:hover {
  background-color: #4a0963;
}

@media (max-width: 768px) {
  .report-sales-summary-container {
    flex-direction: column;
    align-items: center;
  }

  .report-sales-summary {
    width: 100%;
    margin-bottom: 20px;
  }
}
.revenue-event-table-container .table-ellipsis .ellipsis {
  float: right;
}
.reports-charts-card-container {
  display: flex;
  gap: 28px;
}

.reports-charts-card {
  width: 100%;
  justify-self: stretch;
  height: 100%;
  margin-top: 30px;
  /* padding: 24px; */
  /* border-radius: 14px; */
  /* box-shadow: 6px 6px 54px 0 rgba(0, 0, 0, 0.05); */
}
/* Email Selector */

.email-selector ul.option-list-container {
  width: fit-content;
  padding-left: 0;
  padding-right: 0;
}
.email-selector button.report-action-button {
  gap: 16px;
}

/* Email Report Modal */
.email-report-modal {
  position: fixed;
  inset: 0;
  background: rgba(110, 68, 154, 0.25);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.email-report-content {
  background-color: #ffffff;
  padding: 30px;
  border-radius: 12px;
  max-width: 600px;
  width: 100%;
  position: relative;
}

.email-report-header {
  margin-bottom: 9px;
  display: flex;
  justify-content: space-between;
  align-items: start;
}

.email-report-content h2 {
  font-size: 20px;
  font-weight: 600;
}

.email-close-button {
  font-size: 24px;
  background: none;
  border: none;
  cursor: pointer;
  font-weight: 500;
}

.email-file-type-options,
.email-schedule-options {
  display: flex;
  margin: 20px 0;
}

.file-option {
  background: #fff;
  border: 1px solid #eaeaea;
  font-size: 12px;
}
.file-option:first-child {
  border-radius: 4px 0 0 4px;
}
.file-option:last-child {
  border-radius: 0 4px 4px 0;
}

.file-option,
.schedule-option {
  padding: 6px 36px;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 8px;
  color: rgba(0, 0, 0, 0.77);
  font-weight: 500;
}

.file-option.selected {
  background-color: #f9f3ff;
  border: 1px solid #891bb2;
}
.email-report-modal .hidden {
  opacity: 0;
}

.email-schedule-options {
  background-color: #f5f6fa;
  padding: 4px;
  border-radius: 12px;
  width: fit-content;
}

.schedule-option {
  padding: 8px 12px;
  background: inherit;
  border: none;
  font-size: 14px;
}
.schedule-option.selected {
  background: white;
  border-radius: 8px;
  border: 1px solid #e8e8e8;
}

.email-input::placeholder {
  color: rgba(0, 0, 0, 0.2);
}
.email-input {
  width: 100%;
  padding: 10px;
  margin-bottom: 20px;
  border: 1px solid #979797;
  border-radius: 8px;
  outline: none;
  font-weight: 500;
}

.schedule-button:hover {
  opacity: 0.5;
}
.schedule-button,
button.schedule-button:hover {
  color: #891bb2;
  border: 1px solid #891bb2;
  background: transparent;
  font-weight: 600;
}

/* EMAIL REPORT SUCCESS MODAL */
.email-modal-container {
  position: relative;
  width: fit-content;
}
.email-modal-container .option-list-container.ellipsis-option {
  right: -70px;
}
.email-report-success-modal-container {
  max-width: 600px;
  width: 100%;
  background: white;
  padding: 65px 52px;
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  position: relative;
  z-index: 10;
}
.email-report-success-modal-container-small {
  padding-top: 28px;
  padding-bottom: 28px;
}

.email-report-success-modal-header {
  margin-bottom: 20px;
}
.email-report-success-modal-header h2 {
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 8px;
}
.email-report-success-modal-header p {
  font-weight: 500;
  color: rgba(0, 0, 0, 0.52);
}
.email-report-success-modal-header p span {
  font-weight: 500;
  color: #000;
}
.email-success-close-button {
  border: 0;
  background: transparent;
  align-self: flex-end;
  position: absolute;
  top: 24px;
  right: 40px;
}
.email-success-close-button-small {
  top: 24px;
  right: 24px;
}

/* REPORT ITEM PAGE */
.report-item-container {
  border-radius: 12px;
  margin: 20px auto;
  display: flex;
  gap: 24px;
}

.report-items-container-a {
  width: fit-content;
}

.report-item-title {
  font-size: 24px;
  font-weight: bold;
  color: #5a0b77;
  margin-bottom: 20px;
}

.report-item-event-info {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  border: 1px solid #e8e8e8;
  border-radius: 16px;
  background: white;
  width: max-content;
  padding: 12px 19px 12px 12px;
  box-shadow: 6px 6px 54px 0 rgba(0, 0, 0, 0.08);
}

.report-item-event-image {
  width: 100px;
  height: 100px;
  border-radius: 8px;
  object-fit: cover;
  margin-right: 20px;
}

.report-item-event-details .event-venue-date {
  margin-bottom: 0;
}
.report-item-event-details {
  flex: 1;
}

.report-item-ticket-views {
  border: 1px solid #e8e8e8;
  border-radius: 16px;
  padding: 28px 24px;
  box-shadow: 6px 6px 54px 0 rgba(0, 0, 0, 0.08);
  flex: 1;
}

.report-item-metrics {
  display: flex;
  gap: 20px;
  margin: 20px 0;
}

.reports-trend-container {
  border-bottom: 1px solid #c2c2c2;
  padding: 0 !important;
  border-radius: 0 !important;
}
.reports-views-trend {
  display: flex;
  gap: 12px;
  align-items: start;
  margin-bottom: 16px;
}

.reports-views-container {
  padding: 28px;
}

.reports-item-metrics-container {
  width: 100%;
}

.report-item-metric {
  border-radius: 8px;
  padding: 28px 24px;
  flex: 1 1 250px;
  border: 1px solid #e8e8e8;
  justify-self: stretch;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  box-shadow: 6px 6px 54px 0 rgba(0, 0, 0, 0.08);
  background: white;
}
.report-item-metric-child-container {
  display: flex;
  justify-content: space-between;
}

.report-item-metric h4 {
  font-size: 28px;
  font-weight: 600;
}
.reports-pie-chart {
  width: 80px;
  height: 80px;
}
#legend-container {
  height: fit-content;
}
#legend-container ul {
  display: flex;
  height: fit-content;
  gap: 14px;
  margin-bottom: 0;
}
#legend-container li {
  font-size: 8px;
  cursor: pointer;
  height: fit-content;
  list-style: none;
}
#legend-container li span {
  display: flex;
  height: fit-content;
  align-items: center;
  column-gap: 4px;
}
#legend-container li span div {
  width: 8px;
  height: 8px;
}
#legend-container li span p {
  margin-bottom: 0;
}
.reports-legend-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 16px;
}
.report-item-metric-title {
  font-size: 12px;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.77);
  text-align: left;
}
.report-ticket-value {
  padding-bottom: 28px;
  margin-bottom: 0;
  text-align: left !important;
  font-weight: 600;
}
.reports-views-container h4 {
  padding-bottom: 0;
}
.reports-views-container span {
  text-align: left;
}
.reports-views-container {
  border-bottom: 0;
}

.report-item-ticket-category {
  margin-top: 20px;
  border: 1px solid #e8e8e8;
  border-radius: 16px;
  background: white;
  padding: 36px;
  margin-bottom: 0;
}
.report-item-ticket-category table tr:nth-child(even) td {
  background: transparent;
}
.report-item-ticket-category h3 {
  font-weight: 600;
  font-size: 20px;
  color: rgba(0, 0, 0, 0.77);
  margin-bottom: 27px;
}

.report-item-ticket-table {
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0 !important;
  margin-top: 10px;
  height: fit-content;
  border: 1px solid #e8e8e8;
}

.report-item-ticket-table th {
  color: #5a0b77;
  font-weight: 600;
  font-size: 12px;
  padding: 12px 16px;
  border-bottom: 4px solid #e5e9eb;
}
.revenue-event-table-container table td {
  padding: 7px;
}

.report-item-ticket-table tr td {
  padding-top: 26px;
}
.report-item-ticket-table tr:last-child td {
  border: 0;
}
.report-item-ticket-table td {
  border-bottom: 1px solid #ddd;
  padding: 10px;
  text-align: left;
  font-size: 14px;
  color: #333;
}

.report-item-ticket-table th {
  font-weight: bold;
}

.report-item-ticket-table tr:hover {
  background-color: #f1f1f1;
}

/* Responsive Styles */
@media (max-width: 768px) {
  .report-item-metrics {
    flex-direction: column;
  }
}

.reports-event-info-side-container {
  display: flex;
  flex-direction: column;
}

/* REPORT ORDERS ITEMS TABLE  */
.report-items-orders-table {
  background: white;
  border: 1px solid #e8e8e8;
  padding: 36px;
  border-radius: 16px;
  margin-bottom: 54px;
}
.report-items-orders-table th {
  background: #fbfcff;
}
.report-sales-summary-table-container {
  padding: 24px 48px;
  box-shadow: none;
}
.report-sales-summary-table-container .report-sales-summary {
  padding: 0;
}

/* REPORT HISTORY */

.reports-history-container {
  margin: 20px;
  font-family: Arial, sans-serif;
}

.reports-history-title {
  font-size: 28px;
  color: #6e449a;
  margin-bottom: 39px;
  font-weight: 600;
}

.reports-tabs,
.reports-filter {
  display: flex;
  margin-bottom: 20px;
  height: 48px;
  background: #f5f6fa;
}

.report-tab {
  height: 100%;
  border-radius: 8px;
  cursor: pointer;
  color: #292929;
  border: none;
  border-radius: 0;
  display: flex;
  background: inherit;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  width: 200px;
}

.report-tab.active {
  font-weight: 600;
  color: #5a0b77;
  border-bottom: 2px solid #5a0b77;
}

.report-history-header {
  display: flex;
  flex-wrap: wrap;
  gap: 11px;
}
.reports-filter {
  display: flex;
  margin-bottom: 20px;
}

.reports-filter button {
  padding: 8px;
  border-radius: 0.2px;
  border-right: 1px solid rgba(0, 0, 0, 0.22);
  width: 111px;
  font-size: 14px;
}

button.reset-filter-btn {
  border: 0 !important;
  background: none;
  border: none;
  color: #ea0234;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 5px;
  padding: 0 23px 0 13px !important;
  width: fit-content;
}
.report-filter-img {
  padding: 0 13px 0 18px;
  border-right: 1px solid rgba(0, 0, 0, 0.22);
  display: flex;
  align-items: center;
}
.report-filter-img img {
  width: 24px;
  height: 24px;
}

.report-history-table {
  border: 0;
}
.report-history-table td {
  color: #252c32;
  font-weight: 500;
}
.report-history-table th {
  color: #252c32;
  font-weight: 600;
}

.report-history-btn-edit-img {
  width: 16px;
}
.report-history-btn-forward-img {
  width: 16.5px;
}
.reports-history-container .action-buttons button:nth-child(3) img {
  width: 17.95px;
}
.reports-history-container .action-buttons {
  margin-bottom: 0;
  gap: 0;
  width: fit-content;
}
.reports-history-container .action-buttons button.action-button {
  background: none;
  border: none;
  cursor: pointer;
  width: 40px;
  height: 40px;
}

.report-history-actions-head {
  width: 12%;
}

.filter-by-modal-container {
  position: relative;
  width: fit-content;
}
.filter-by-modal-container button.report-tab.is-date {
  width: fit-content;
  padding: 0 10px;
}

.filter-calendar-content {
  width: fit-content;
  border-radius: 26px;
  background: white;
  border: 1px solid rgba(81, 86, 99, 0.2417);
  overflow: hidden;
  position: absolute;
  box-shadow: 0 13px 61px 0 rgba(169, 169, 169, 0.366);
  z-index: 9;
}

.filter-calendar-content .react-datepicker__current-month,
.react-datepicker-time__header,
.react-datepicker-year-header {
  text-align: left;
  padding-left: 34px;
  padding-right: 34px;
  padding-top: 25px;
  font-size: 15px !important;
  font-weight: 500;
  padding-bottom: 27px;
  border-bottom: 0.4px solid #e7e9ee;
}
.filter-calendar-content .react-datepicker,
.react-datepicker__header {
  background: white !important;
}

.filter-calendar-content .react-datepicker__month {
  margin: 0;
  padding: 0.4rem 27px 24px;
  border-bottom: 0.4px solid #e7e9ee;
}
.filter-calendar-content
  .react-datepicker__navigation.react-datepicker__navigation--next {
  right: 18px;
}

.filter-calendar-content
  .react-datepicker__navigation.react-datepicker__navigation--previous {
  left: 80%;
}

.filter-calendar-content
  .react-datepicker__navigation.react-datepicker__navigation--previous,
.react-datepicker__navigation.react-datepicker__navigation--next {
  background: #e7e9ee;
  box-shadow: none;
  border-radius: 4px;
  width: 22px;
  height: 22px;
  top: 31px;
  border: none;
}
.filter-calendar-content .react-datepicker__day-name,
.react-datepicker__day,
.react-datepicker__time-name {
  font-size: 15px;
  font-weight: 600;
  color: #999999 !important;
}

.filter-calendar-content .react-datepicker__week div {
  box-shadow: none;
  border: none;
}

.react-datepicker__day--selected:hover {
  background: #891bb2 !important;
}
.react-datepicker__day--selected {
  background: #891bb2 !important;
  color: white !important;
}

.react-datepicker__day--keyboard-selected,
.react-datepicker__month-text--keyboard-selected,
.react-datepicker__quarter-text--keyboard-selected,
.react-datepicker__year-text--keyboard-selected {
  background-color: #891bb2 !important;
  opacity: 50% !important;
  color: white !important;
}

.filter-calendar-content .react-datepicker__day-name,
.react-datepicker__day,
.react-datepicker__time-name {
  width: 40px !important;
  height: 40px !important;
  border-radius: 12px !important;
}
.filter-calendar-content
  .react-datepicker__day-names
  .react-datepicker__day-name {
  color: #666666 !important;
}
.filter-calendar-content .react-datepicker__day-names {
  margin-top: 20px;
}

.filter-by-modal-container .report-tab {
  gap: 22px;
}
.filter-by-modal-container .report-tab img {
  width: 12px;
}

.filter-calendar-content .footer {
  padding-bottom: 27px;
}
.filter-calendar-content .footer p {
  padding: 16px 32px 16px 31px;
  color: #434343;
  font-size: 14px;
  margin-bottom: 0;
}
.filter-calendar-content .footer button {
  background: #891bb2;
  padding: 9px 33px;
  border-radius: 6px;
  outline: none;
  color: white;
  border: none;
  font-size: 12px;
  font-weight: bold;
  width: fit-content;
}
.filter-calendar-content .footer div {
  display: flex;
  justify-content: center;
}
.filter-by-modal-backdrop {
  position: fixed;
  inset: 0;
}

.reports-history-container .action-buttons .event-button-group button {
  background: #891bb2;
  width: 200px;
  height: fit-content;
}

.forward-report-modal {
  background: rgba(110, 68, 154, 0.25);
}

.forward-cancel-modal .email-report-success-modal-container {
  max-width: 465px;
}

.sales-analytics-container {
  width: 100%;
  max-width: 400px; /* Adjust the width as needed */
  margin: 0 auto; /* Center the chart */
  background-color: #ffffff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.05);
}

/* Layout */
.events-details-container {
  display: flex;
  align-items: flex-start;
  gap: 3rem; /* More space between image and info */
  padding: 2rem;
  background-color: #f9f3ff; /* Light background for section */
}

.events-details-image-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
}

.events-details-image {
  width: 250px;
  height: auto;
  border-radius: 12px;
  object-fit: cover;
}

/* Button styling */
.events-details-change-image-btn {
  background-color: white;
  border: 2px solid #a64ede;
  color: #a64ede;
  padding: 10px 20px;
  border-radius: 8px;
  cursor: pointer;
  font-size: 1rem;
  font-weight: 500;
}

.events-details-change-image-btn:hover {
  background-color: #f0e5ff;
}

/* Info section styling */
.events-details-info {
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-family: "Arial", sans-serif;
  gap: 1rem; /* Add space between details */
}

.events-details-title {
  color: #6e449a;
  font-size: 36px;
  font-weight: 700; /* Bold font for the title */
  margin-bottom: 1rem;
}

.events-details-date,
.events-details-time,
.events-details-location {
  color: #333;
  font-size: 12px;
  font-weight: 600; /* Normal weight for the text */
  margin: 0;
  line-height: 1.6; /* Adjusted line height for readability */
  text-transform: uppercase;
}

/* Add underline to info text to match the design */
.events-details-date::after,
.events-details-time::after,
.events-details-location::after {
  content: "";
  display: block;
  width: 100%;
  height: 1px;
  background-color: #ccc;
  margin-top: 0.5rem;
}

/* Container */
.events-details-body {
  padding: 2rem;
  color: #333;
}

/* Tabs */
.events-details-tabs {
  display: flex;
  border-bottom: 2px solid #ddd;
  margin-bottom: 1.5rem;
}

.events-details-tabs button {
  background: transparent;
  border: none;
  padding: 1rem 2rem;
  cursor: pointer;
  font-weight: 600;
  color: #292929;
  font-size: 16px;
}

.events-details-tabs button.active-tab {
  border-bottom: 3px solid #5a0b77;
  color: #5a0b77;
}

/* Table styling */
.events-details-table table {
  width: 100%;
  border-collapse: collapse;

  font-size: 12px;
}

.events-details-table th,
.events-details-table td {
  text-align: left;
  padding: 1.2rem;
  border-bottom: 1px solid #ddd;
  font-weight: 500;
}

.events-details-table th {
  text-transform: uppercase;
  font-weight: 600;
}

.events-details-table .highlight {
  background-color: #f5f2ff;
  color: #333;
}

.events-details-table .status {
  font-weight: 600;
  text-transform: uppercase;
}

/* Button styling */
.add-tickets-btn {
  margin-top: 1.5rem;
  padding: 0.8rem 2rem;
  background-color: #891bb2;
  color: white;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  font-size: 16px;
  font-weight: 600;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.add-tickets-btn:hover {
  background-color: #891bb2;
  opacity: 90%;
}
.table-container {
  width: 100%;
  overflow: hidden;
}

.table-title {
  font-size: 24px;
  font-weight: 600;
  margin-bottom: 20px;
  color: #333;
}

.transaction-payment-method .custom-table th {
  color: #5a0b77;
}
.transaction-payment-method {
  max-width: 856px;
  width: 100%;
  padding-top: 27px;
  padding-bottom: 29px;
  background: #fff;
  padding-left: 28px;
  padding-right: 28px;
  border: 1px solid #e8e8e8;
  border-radius: 16px;
}

.custom-table {
  width: 100%;
  border-collapse: collapse;
  background-color: #ffffff;

  overflow: hidden;
  font-family: Arial, sans-serif;
}

.custom-table th,
.custom-table td {
  padding: 15px;
  text-align: left;
  border-bottom: 1px solid #e5e9eb;
  color: #444;
}

.custom-table th {
  border-bottom: 4px solid #e5e9eb;
  font-weight: bold;
  /* color: #666; */
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 600;
  color: #252c32;
}

.custom-table tr:hover {
  background-color: #f9f9f9;
}

.custom-table tr:last-child td {
  border-bottom: none;
}

.custom-table tbody tr td {
  white-space: nowrap;
}

.custom-table td {
  font-size: 14px;
  font-weight: 500;
  color: #333;
}

.custom-table td a {
  color: #0366d6;
  text-decoration: none;
}

.custom-table td a:hover {
  text-decoration: underline;
}

/* Specific styling for table elements */
.custom-table th:first-child,
.custom-table td:first-child {
  padding-left: 20px;
}

.custom-table th:last-child,
.custom-table td:last-child {
  padding-right: 20px;
}

.custom-table .menu-icon {
  cursor: pointer;
  color: #888;
  font-size: 18px;
  padding: 0 10px;
  vertical-align: middle;
}

.custom-table .menu-icon:hover {
  color: #555;
}
.simple-ellipsis-icon {
  cursor: pointer;
  width: 24px;
  height: 24px;
  position: relative;
}

.simple-ellipsis-option-list {
  position: absolute;
  background-color: #ffffff;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  list-style: none;
  padding: 10px;
  border-radius: 4px;
  z-index: 10;
  right: 40px;
  min-width: 15rem;
}

.simple-ellipsis-option-list li {
  padding: 8px 12px;
  cursor: pointer;
  color: #333;
}

.simple-ellipsis-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0);
  z-index: 9;
}
.tabs-container {
  display: flex;
  margin-bottom: 20px;
  border-bottom: 2px solid #e0e0e0;
  background: #f5f6fa;
  width: fit-content;
  margin-top: 8px;
}

.tab-button {
  padding: 16px 20px;
  font-size: 16px;
  color: #666;
  font-weight: 500;
  background: none;
  border: none;
  cursor: pointer;
  margin-right: 20px;
  position: relative;
}

.tab-button.active {
  color: #5a0b77;
  font-weight: 600;
}

.tab-button.active::after {
  content: "";
  position: absolute;
  bottom: -2px;
  left: 0;
  right: 0;
  height: 2px;
  background-color: #5a0b77;
  border-radius: 1px;
}

.user-details-container {
  padding: 20px;
  font-family: Arial, sans-serif;
}

.user-details-card {
  background-color: #faf7ff;
  border-radius: 12px;
  padding: 30px;

  margin-bottom: 20px;
  border: 1px solid #828282bd;
}

.user-details-section-title {
  font-size: 28px;
  font-weight: 500;
  color: #6e449a;
  margin-bottom: 25px;
}

.user-details-info-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
}

.user-details-info-item {
  display: flex;
  flex-direction: column;
  /* margin-bottom: 15px; */
}

.user-details-label {
  font-size: 20px;
  font-weight: 600;
  color: #333;
  margin-bottom: 8px;
}

.user-details-value {
  font-size: 16px;
  color: #252c32;
  font-weight: 500;
}

.user-details-action-buttons {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.user-details-action-button {
  padding: 12px 20px;
  background-color: transparent;
  color: #891bb2;
  border: 2px solid #891bb2;
  border-radius: 8px;
  cursor: pointer;
  font-size: 16px;
  font-weight: 600;
  flex: 1;
  margin-right: 10px;
  transition: background-color 0.3s, color 0.3s;
}
.user-details-inactive-button {
  padding: 12px 20px;
  background-color: transparent;
  color: grey;
  border: 2px solid grey;
  border-radius: 8px;
  cursor: not-allowed;
  font-size: 16px;
  font-weight: 600;
  flex: 1;
  margin-right: 10px;
}
.user-details-block-button {
  padding: 12px 20px;
  background-color: transparent;
  color: red;
  border: 2px solid red;
  border-radius: 8px;
  /* cursor: not-allowed !important; */
  font-size: 16px;
  font-weight: 600;
  flex: 1;
  margin-right: 10px;
}

.user-details-unblock-button {
  background-color: #28a745;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 4px;
  font-weight: 500;
  flex: 1;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.user-details-unblock-button:hover {
  background-color: #218838;
}

/* Modal styling for unblock button */
.unblock-button {
  background-color: #28a745;
  color: white;
  border: none;
  width: 100%;
  padding: 12px;
  border-radius: 4px;
  font-weight: 500;
  cursor: pointer;
  margin-top: 20px;
  transition: background-color 0.3s ease;
}

.unblock-button:hover {
  background-color: #218838;
}

.unblock-button:disabled {
  background-color: #8bc29a;
  cursor: not-allowed;
}

.user-details-action-button:last-child {
  margin-right: 0;
}

.user-details-action-button:hover {
  background-color: #891bb2;
  color: #ffffff;
}
.user-details-approval {
  margin-bottom: 33px;
}
.cancel-modal-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(110, 68, 154, 0.25);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.success-modal,
.funds-modal,
.block-wallet-modal {
  background-color: #fff;
  padding: 30px;
  border-radius: 10px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
  max-width: 500px;
  width: 100%;
  position: relative;
  text-align: center;
}

/* .success-header,
.funds-header,
.block-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  text-align: center;
} */

.close-button {
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
}

.funds-body,
.block-body {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.input-field {
  padding: 12px;
  border: 0.4px solid #979797;
  border-radius: 8px;
  font-size: 16px;
  width: 100%;
  outline: none;
  font-weight: 500;
}
.input-field::placeholder {
  color: rgba(0, 0, 0, 0.2);
}

.funds-button:disabled {
  background: #e8e8e8 !important;
}
.funds-button {
  padding: 12px;
  font-size: 16px;
  border-radius: 8px;
  cursor: pointer;
  border: none;
  color: #fff;
  font-weight: 600;
  margin: auto;
}

.funds-button.add {
  background-color: #891bb2;
}

.funds-button.debit {
  background-color: #891bb2;
}

.block-button {
  padding: 12px 20px;
  background-color: #891bb2;
  color: #fff;
  border: none;
  border-radius: 8px;
  font-size: 16px;
  cursor: pointer;
  width: fit-content;
  margin: auto;
  font-weight: 600;
}

.success-icon {
  width: 163px;
  height: 126px;
  margin-bottom: 15px;
}

/* .success-amount {
  font-size: 28px;
  font-weight: bold;
  color: #6b4bfc;
  margin-bottom: 10px;
} */

.success-message {
  font-size: 16px;
  color: rgba(0, 0, 0, 0.52);
  max-width: 259px;
  margin: auto;
  font-weight: 500;
}

/* Hide the arrows in number input */
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield; /* Firefox */
}

/* Optional: Keep other input styling consistent */
.number-input {
  padding: 12px;
  border: 1px solid #ccc;
  border-radius: 8px;
  font-size: 16px;
  width: 100%;
}
/* Container and Title */
.settings-container {
  padding: 20px;
  font-family: Arial, sans-serif;
}

.settings-title {
  font-size: 32px;
  font-weight: 600;
  color: #6e449a; /* Adjust to match the purple from the design */
  margin-bottom: 28px;
}

/* Settings Option Styles */
.settings-option {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 0;
  border-bottom: 1px solid #e0e0e0;
  font-size: 36px;
  color: #333;
  max-width: 377px;
  width: 100%;
  padding-left: 16px;
  padding-right: 7px;
}

.settings-option-title {
  font-size: 14px;
  font-weight: 500;
}

/* Toggle Switch Styles */
.settings-toggle-switch {
  position: relative;
  display: inline-block;
  /* width: 60px;
  height: 34px; */
  width: 44px;
  height: 24px;

  cursor: pointer;
}

.settings-toggle-slider {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  /* border-radius: 34px; */
  border-radius: 24px;
  background-color: #ccc;
  transition: 0.4s;
  background: rgba(120, 120, 128, 0.16);
}

.settings-toggle-slider:before {
  position: absolute;
  content: "";
  /* height: 26px;
  width: 26px; */
  height: 20px;
  width: 27px;
  /* left: 4px;
  bottom: 4px; */
  left: 2px;
  bottom: 2px;

  /* border-radius: 50%; */
  border-radius: 24px;
  background-color: #fff;
  transition: 0.4s;
  box-shadow: 0px 0px 0px 1px rgba(0, 0, 0, 0.04),
    0px 3px 8px rgba(0, 0, 0, 0.15), 0px 3px 1px rgba(0, 0, 0, 0.06);
}

.settings-toggle-input {
  opacity: 0;
  width: 0;
  height: 0;
}

.settings-toggle-input:checked + .settings-toggle-slider {
  background-color: #6e449b; /* Adjusted to the purple color from the design */
}

.settings-toggle-input:checked + .settings-toggle-slider:before {
  /* transform: translateX(26px); */
  transform: translateX(13px);
}

/* Users */
.wallet-users-header {
  font-size: 36px;
}

.success-modal {
  padding-bottom: 84px !important;
}

.block-message {
  font-weight: 500;
  color: rgba(0, 0, 0, 0.52);
  max-width: 382px;
  margin: auto;
  margin-bottom: 42px;
}

.block-wallet-modal {
  padding-bottom: 71px !important;
}

/*Group header  */
.oja-dashboard-header.group-header {
  margin-bottom: 36px;
}
.oja-dashboard-header.group-header h1 {
  font-size: 36px;
}
.oja-dashboard-header.group-header .group-button {
  background: #6918b4;
  color: white;
  height: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  font-weight: 600;
  border-radius: 8px;
}
.group-button-container {
  display: flex;
  gap: 24px;
}

.oja-dashboard-header.group-header .group-button-outlined {
  background: white;
  border: 1px solid #6918b4;
  color: #6918b4;
}

/* GROUP INPUT MODAL COMPONENT */
.funds-modal .group-no-text-area {
  margin-bottom: 51px;
}
.funds-modal .group-text-area {
  margin-bottom: 32px;
}
.funds-modal .funds-button,
.block-button {
  max-width: 200px;
  width: 100%;
  background: #6918b4;
}

.funds-modal,
.success-modal,
.block-wallet-modal {
  padding: 34px;
  padding-top: 55px;
  position: relative;
  max-width: 600px;
  width: 100%;
  border-radius: 16px;
  box-shadow: none;
}

.group-input-header,
.success-header,
.funds-header,
.block-header {
  margin-bottom: 30px;
}
.group-input-header button,
.success-header button,
.funds-header button,
.block-header button {
  position: absolute;
  right: 30px;
  top: 0;
  font-size: 58px;
}
.group-input-header h2,
.success-header h2,
.funds-header h2,
.block-header h2 {
  font-size: 20px;
  font-weight: 600;
  margin: 0;
  margin-bottom: 8px;
}

/* ACTION MODAL COMPONENT */
.action-modal-subtitle {
  margin: 0;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.52);
  text-align: center;
}
.action-modal-subtitle1 {
  width: 362px;
  margin: auto;
}
.action-modal-subtitle2 {
  width: 314px;
  margin: auto;
}

.funds-modal.group-input.action-modal .group-input-header {
  margin-bottom: 0;
}
/* .funds-modal.group-input.action-modal .group-input-header h2 {
  margin-bottom: 8px;
} */
.funds-modal.group-input.action-modal .group-input-header h2.img-header {
  margin-bottom: 57px;
}
.funds-modal.group-input.action-modal .group-input-header img {
  margin: auto;
  margin-bottom: 34px;
}

.funds-modal.group-input.action-modal .image {
  margin-top: 30px !important;
}

.funds-modal.group-input.action-modal .no-image {
  margin-top: 38px !important;
}

/* ADD GROUP USER SCREEN  */
.custom-table td input[type="radio"] {
  accent-color: #891bb2;
  width: 15px;
  height: 15px;
}

.custom-table td .custom-radio-input {
  width: 15px;
  height: 15px;
  border-radius: 100%;
  border: 1px solid #891bb2;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  cursor: pointer;
}

.custom-table td .custom-radio-input div {
  width: 9px;
  height: 9px;
  border-radius: 100%;
  background: #891bb2;
  position: absolute; /* Center this absolutely */
  top: 50%;
  left: 50%;
  transform: translate(-55%, -54%);
}

.no-group-users {
  color: rgba(0, 0, 0, 0.52) !important;
  max-width: 314px;
  margin: auto;
  font-weight: 500;
  text-align: center;
  padding-top: 10%;
  padding-bottom: 10%;
  font-size: 16px;
  text-wrap: pretty;
}

/* WALLET REQUESTS */
.requests-header {
  color: #6e449a;
  font-size: 36px;
  font-weight: 600;
  margin: 0;
  margin-top: 8px;
}

.user-request-header {
  padding-left: 20px;
  display: flex;
  align-items: start;
  gap: 21px;
  margin-bottom: 12px;
}

.user-request-details {
  max-width: 857px;
  width: 100%;
}
.user-request-details .user-request-details-action-buttons {
  width: 50%;
}
.user-request-details .user-details-container {
  padding-top: 0;
}

.user-additional-info {
  border: 1px solid black;
  padding: 16px;
  border-radius: 16px;
  height: 136px;
}

.request-additional-information {
  text-overflow: ellipsis;
  text-wrap: nowrap;
  overflow: hidden;
}

.user-request-details-container {
  width: 80%;
}

/* NAVIGATION BUTTON */
.order-details-action-btn.request-nav-btn {
  margin-bottom: 0 !important;
  width: fit-content;
}

.order-details-action-btn.request-nav-btn button {
  background-color: transparent;
}

/* RE ORDER LIST */
.reordered-list-container .custom-table {
  border: 0px;
}
.reordered-list-container .custom-table td {
  color: #252c32;
  font-weight: 500;
  padding-bottom: 8px;
  padding-top: 8px;
}
.reordered-list-container .report-actions-container {
  margin-bottom: 20px;
}
.reordered-list-container .re-ordered-search-container {
  margin-bottom: 27px;
  margin-top: 27px;
}
.reordered-list-container .custom-table th {
  color: "#252C32";
  font-weight: bold;
  font-size: 16px;
  text-transform: none;
  padding-bottom: 8px;
  padding-top: 0;
}

.reordered-list-container .form-control,
.pay-transactions-container .form-control {
  border-radius: 12px;
  border-color: #eaeaea;
  max-width: 194px;
  width: 100% !important;
  color: rgba(130, 130, 130, 0.74);
  font-size: 14px;
  padding: 13px 16px;
}

/* PAY TRANSACTINS */

.pay-transactions-container .form-control {
  max-width: 194px;
  width: 100% !important;
  border-radius: 12px;
  color: rgba(130, 130, 130, 0.74);
  font-size: 14px;
  padding: 13px 16px;
}
.pay-transactions-container .re-ordered-search-container {
  margin-bottom: 24px;
  margin-top: 24px;
}
.pay-transactions-container .custom-table {
  border: none;
}
/* PAY TRANSACTION DETAILS CONTAINER */
.pay-transaction-details-container .user-details-info-grid {
  gap: 16px;
}
.pay-transaction-details-container .user-pay-details-section-title {
  margin-top: 32px;
}
.pay-transaction-details-container .user-details-section-title {
  margin-bottom: 16px;
}

/* ALL ORDERS */
.all-orders-header {
  display: flex;
  justify-content: space-between;
  align-items: start;
}
.all-orders-header button.high-demand-btn,
.all-orders-header button.high-demand-btn-red {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 32px;
  font-size: 16px;

  border-radius: 8px;
  font-weight: 500;
  width: 188px;
  height: 32px;
}

.all-orders-header button.high-demand-btn {
  color: black;
  background: transparent;
  border: 1px solid #6e7c87;
}
.all-orders-header button.high-demand-btn-red {
  background-color: #ef3826;
  color: white;
}

.all-orders-header h2 {
  letter-spacing: -0.022em;
  font-family: "Clash Display";
  font-style: normal;
  font-weight: 700;
  font-size: 36px;
  line-height: 48px;
  color: #6e449a;
  margin-top: 0;
}

.high-demand-switch-container {
  display: flex;
  gap: 16px;
  align-items: center;
}

.high-demand-modal-container .group-input-header p {
  font-family: "Clash Display";
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 100%;
  text-align: center;
  color: #000000;
}
.high-demand-modal-container .high-btn-container {
  display: flex;
  gap: 16px;
  justify-content: center;
}

.high-demand-modal-container .high-btn-container button.funds-button {
  margin: 0;
}
.high-demand-modal-container button.high-btn-yes {
  background: transparent;
  border: 1px solid #6918b4;
  color: #6918b4;
  align-items: center;
  justify-content: center;
}

.high-demand-modal-container .group-input-header .img-header {
  font-weight: 700;
  font-size: 28px;
  line-height: 48px;
  letter-spacing: -0.022em;
  margin-bottom: 13px !important;
  color: #6e449a;
}
.high-demand-modal-container .group-input-header p {
  margin-bottom: 70px;
}

.all-orders-header .settings-toggle-input:checked + .settings-toggle-slider {
  background-color: #ef3826;
}
.pay-categories-body {
  margin-top: 48px;
}
.add-new-categories {
  border: 1px solid #5a0b77;
  display: flex;
  flex-direction: column;
  gap: 2px;
  min-height: 120px;
  justify-content: center;
  align-items: center;
  border-radius: 18px;
}
.add-new-categories p {
  margin-bottom: 0px;
  color: #6918b4;
  font-size: 17px;
  font-weight: 600;
}
/* Container */
.add-biller-container {
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;
  width: 100%;
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  font-family: Arial, sans-serif;
}

.add-biller-title {
  font-size: 24px;
  font-weight: 600;
  color: #6c43b3;
  text-align: left;
  margin-bottom: 20px;
}

/* Form */
.add-biller-form {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

/* Upload Container */
.add-biller-upload-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 120px;
  height: 120px;
  border: 2px solid #ccc;
  border-radius: 10px;

  cursor: pointer;
}

.add-biller-upload-label {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: #999;
  font-size: 14px;
}

.add-biller-upload-icon {
  font-size: 24px;
  margin-bottom: 5px;
}

.add-biller-uploaded-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 10px;
}

.add-biller-upload-input {
  display: none;
}

/* Input Fields */
.add-biller-input-field,
.add-biller-textarea-field {
  width: 100%;
  padding: 10px;
  border: 1px solid #e0e0e0;
  border-radius: 5px;
  font-size: 14px;
  outline: none;
}

.add-biller-input-field::placeholder,
.add-biller-textarea-field::placeholder {
  color: #999;
}

.add-biller-textarea-field {
  min-height: 80px;
  resize: none;
}

/* Save Button */
.add-biller-save-button-container {
  display: flex;
  justify-content: end;
}
.add-biller-save-button {
  background-color: #ffcc00;
  color: #333;
  font-size: 16px;
  font-weight: 600;
  padding: 10px 42px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: all 0.2s ease;
}

.add-biller-save-button:hover {
  background-color: #e6b800;
}
.add-biller-header {
  display: flex;
  justify-content: space-between;
  align-items: top;
}
.pay-commission-container {
  width: 100%;
  padding: 20px;
  box-sizing: border-box;
}

.form-container {
  background: #fff;

  width: 100%;
  max-width: 600px; /* Adjust based on your layout */
}

.form-container label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
}

.form-container input {
  width: 100%;
  padding: 10px;
  margin-top: 5px;
  margin-bottom: 20px;
  box-sizing: border-box;
  border: 1px solid #ccc;
  border-radius: 4px;
}
.form-container button {
  margin-top: 5px;
  margin-bottom: 20px;
  box-sizing: border-box;
  border-radius: 4px;
  background-color: #ffd964;
  color: black;
  border: none;
  cursor: pointer;
  padding: 16px;
  width: 176px;
}
.react-select-container {
  margin-bottom: 20px;
}
/* ReportsStats Component Styles */

.reports-stats__container {
  display: grid;
  grid-template-columns: repeat(4, 1fr); /* 4 columns layout */
  gap: 20px;
  margin-bottom: 30px;
}

/* For tablet screens */
@media (max-width: 992px) {
  .reports-stats__container {
    grid-template-columns: repeat(2, 1fr); /* 2 columns for tablets */
  }
}

/* For mobile screens */
@media (max-width: 576px) {
  .reports-stats__container {
    grid-template-columns: 1fr; /* 1 column for mobile */
  }
}

.reports-stats__card {
  background: white;
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.05);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.reports-stats__card:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 15px rgba(0, 0, 0, 0.1);
}

.reports-stats__title {
  color: #718096;
  font-size: 14px;
  margin-bottom: 10px;
}

.reports-stats__value {
  font-size: 24px;
  font-weight: 700;
  margin-bottom: 0;
}
.wallet-balance-container {
  margin-top: 40px;
}
/* FundAdminWalletModal.css */

/* Form elements styling */
.fund-input-group {
  margin-bottom: 24px;
}

.fund-input-label {
  display: flex;
  font-size: 14px;
  font-weight: 500;
  color: #000;
  margin-bottom: 8px;
}

.fund-input {
  width: 100%;
  height: 48px;
  padding: 0 16px;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  font-size: 16px;
  color: #000;
  background-color: #fff;
  box-sizing: border-box;
}

.fund-input:focus {
  outline: none;
  border-color: #6033b5;
}

.fund-input::placeholder {
  color: #9e9e9e;
}

.fund-select-container {
  position: relative;
}

.fund-select {
  width: 100%;
  height: 48px;
  padding: 0 16px;
  appearance: none;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  font-size: 16px;
  color: #9e9e9e;
  background-color: #fff;
  cursor: pointer;
  box-sizing: border-box;
}

.fund-select:focus {
  outline: none;
  border-color: #6033b5;
}

.fund-select-arrow {
  position: absolute;
  right: 16px;
  top: 50%;
  transform: translateY(-50%);
  pointer-events: none;
}

.fund-radio-option {
  margin-top: 8px;
}

.fund-radio-container {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.fund-radio-input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

.fund-radio-custom {
  width: 20px;
  height: 20px;
  border: 2px solid #e0e0e0;
  border-radius: 50%;
  display: inline-block;
  position: relative;
  margin-right: 10px;
  flex-shrink: 0;
}

.fund-radio-input:checked + .fund-radio-custom {
  border-color: #6033b5;
}

.fund-radio-input:checked + .fund-radio-custom:after {
  content: "";
  position: absolute;
  top: 3px;
  left: 3px;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background: #6033b5;
}

.fund-radio-label-text {
  font-size: 16px;
  color: #000;
}

.fund-proceed-button {
  width: 100%;
  height: 48px;
  background-color: #e5e5e5;
  color: #fff;
  border: none;
  border-radius: 8px;
  font-size: 16px;
  font-weight: 500;
  cursor: pointer;
  margin-top: 16px;
}

.fund-proceed-button:not([disabled]) {
  background-color: #6033b5;
}

.fund-proceed-button:disabled {
  cursor: not-allowed;
}
.wallet-button-container .wallet-button {
  background: #6918b4 !important;
  color: white;
  height: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  font-weight: 600;
  border-radius: 8px;
}

/* Checkout Iframe Container Styles */
.checkout-iframe-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1000;
  background-color: rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
}

.checkout-iframe-overlay {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.checkout-iframe-wrapper {
  width: 90%;
  max-width: 600px;
  height: 85vh;
  background-color: #fff;
  border-radius: 8px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.15);
}

.checkout-iframe-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px 20px;
  background-color: #f8f9fa;
  border-bottom: 1px solid #eaeaea;
}

.checkout-iframe-header h3 {
  margin: 0;
  font-size: 18px;
  font-weight: 600;
  color: #333;
}

.checkout-iframe-close {
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
  color: #555;
  padding: 0;
  line-height: 1;
}

.checkout-iframe-close:hover {
  color: #000;
}

.checkout-iframe {
  width: 100%;
  height: 100%;
  border: none;
  min-height: 500px;
}

.checkout-error-message {
  padding: 16px;
  background-color: #fff3f3;
  color: #e53935;
  text-align: center;
  margin: 0;
  border-top: 1px solid #ffcdd2;
}

/* Fund Modal Error Message */
.fund-error-message {
  margin-bottom: 15px;
  padding: 12px;
  background-color: #fff3f3;
  border: 1px solid #ffcdd2;
  border-radius: 4px;
  color: #d32f2f;
  font-size: 14px;
}

/* Animation for loading state */
@keyframes fadeInOut {
  0%,
  100% {
    opacity: 0.6;
  }
  50% {
    opacity: 1;
  }
}

.loading-indicator {
  display: inline-block;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  border: 2px solid #ccc;
  border-top-color: #333;
  animation: spin 1s linear infinite;
  margin-right: 8px;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}
