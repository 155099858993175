/* @tailwind base;
@tailwind components;
@tailwind utilities; */

@font-face {
  font-family: clash display;
  font-style: normal;
  font-weight: 400;
  src: local("Clash Display"),
    url(https://fonts.cdnfonts.com/s/65008/ClashDisplayRegular.woff)
      format("woff");
}
@font-face {
  font-family: clash display;
  font-style: normal;
  font-weight: 200;
  src: local("Clash Display"),
    url(https://fonts.cdnfonts.com/s/65008/ClashDisplayExtralight.woff)
      format("woff");
}
@font-face {
  font-family: clash display;
  font-style: normal;
  font-weight: 300;
  src: local("Clash Display"),
    url(https://fonts.cdnfonts.com/s/65008/ClashDisplayLight.woff)
      format("woff");
}
@font-face {
  font-family: clash display;
  font-style: normal;
  font-weight: 500;
  src: local("Clash Display"),
    url(https://fonts.cdnfonts.com/s/65008/ClashDisplayMedium.woff)
      format("woff");
}
@font-face {
  font-family: clash display;
  font-style: normal;
  font-weight: 600;
  src: local("Clash Display"),
    url(https://fonts.cdnfonts.com/s/65008/ClashDisplaySemibold.woff)
      format("woff");
}
@font-face {
  font-family: clash display;
  font-style: normal;
  font-weight: 700;
  src: local("Clash Display"),
    url(https://fonts.cdnfonts.com/s/65008/ClashDisplayBold.woff) format("woff");
}

* {
  font-family: "Clash Display", sans-serif;
  margin: 0;
  text-decoration: none !important;
}

.bg-brand {
  background-color: #6e449b !important;
  color: #ffffff !important;
}
.txt-color {
  color: #6e449b;
}

/* SPINNER STYLES STARTS HERE */

.loading {
  margin-top: 25%;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.loadingSpinnerContainer {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 5000;
  display: flex;
  justify-content: center;
  align-items: center;
}

.loadingSpinner {
  width: 64px;
  height: 64px;
  border: 8px solid;
  border-color: #000 transparent #555 transparent;
  border-radius: 50%;
  animation: spin 1.2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/* SPINNER STYLES STOPS HERE */

/* MODAL STYLES STARTS HERE */

.modal-backdrop {
  background: rgba(62, 14, 84, 0.56);
}

.modal-content {
  border: 1px solid rgba(110, 68, 155, 0.3);
  box-shadow: -4px 4px 17px rgba(0, 0, 0, 0.25);
  border-radius: 8px;
}

.modal-header {
  align-self: center;
  border-bottom: none;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 1px;
  color: #f9971e;
}

.modal-body {
  text-align: center;

  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #6e449b;
}

.modal-footer {
  border-top: none;
  justify-content: space-around;
}

.modal-footer .btn-cancel,
.btn-cancel:active {
  padding: 7px 32px;
  width: 117px;
  height: 38px;
  background: #ffde17 !important;
  border-radius: 8px;
  color: #6e449b !important;
  border: none;
}

.modal-footer .btn-blacklist,
.btn-blacklist:active {
  padding: 7px 32px;
  width: 122px;
  height: 38px;
  background: #d21c1c !important;
  border-radius: 8px;
  border: none;
}

/* MODAL STYLES STOPS HERE */

/* TABLE STYLES STARTS HERE */

.oja-material-ui button {
  color: #000;
}

.cellAction {
  display: flex !important;
  gap: 20px;
}

.viewButton {
  padding: 2px 5px;
  border-radius: 5px;
  color: #000;
  border: 1px solid rgba(0, 0, 139, 0.596);
  cursor: pointer;
}
.editButton {
  padding: 2px 5px;
  border-radius: 5px;
  color: #000;
  border: 1px solid #6e449b;
  cursor: pointer;
}
.deleteButton {
  width: 70px;
  padding: 2px 5px;
  border-radius: 5px;
  color: crimson;
  border: 1px solid rgba(220, 20, 60, 0.6);
  cursor: pointer;
  text-align: center;
}
.assignButton {
  width: 125px;
  padding: 2px 5px;
  border-radius: 5px;
  color: black;
  border: 1px solid #6e449b;
  cursor: pointer;
  text-align: center;
}

.oja-material-ui p {
  margin-bottom: 0;
}

.MuiDataGrid-cell--withRenderer {
  justify-content: end !important;
}

/* TABLE STYLES STOPS HERE */
